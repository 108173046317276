import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/styles/makeStyles'
import { Grid, Typography } from '@material-ui/core'
import YesIcon from 'spypoint/src/form-module/ui/icons/YesIcon'
import WrongIcon from 'spypoint/src/form-module/ui/icons/WrongIcon'
import { useField } from 'formik'
import * as passwordRequirements from '../core/passwordValidation'

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: '11px',
    fontWeight: '400',
  },
  alignIcons: {
    gap: '7px',
    alignItems: 'center',
  },
  smallIcon: {
    '& svg': {
      height: '7.5px',
      width: '10px',
    },
  },
  gap: {
    gap: '25.5px',
  },
}))

const PasswordHintFormik = ({ name }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [field] = useField(name)

  const password = field.value || ''
  const hasLowercaseCharacter = passwordRequirements.hasLowercaseCharacter(password)
  const hasUppercaseCharacter = passwordRequirements.hasUppercaseCharacter(password)
  const hasNumber = passwordRequirements.hasNumber(password)
  const hasSpecialCharacter = passwordRequirements.hasSpecialCharacter(password)
  const hasMinimum10Characters = passwordRequirements.hasMinimum10Characters(password)

  return (
    <Grid container className={classes.gap} style={{ marginTop: 4 }}>
      <Grid item>
        <Grid container className={classes.alignIcons}>
          <Grid className={classes.smallIcon} item>
            { hasLowercaseCharacter
              ? (
                <YesIcon />
                )
              : (
                <WrongIcon />
                ) }
          </Grid>
          <Grid item>
            <Typography className={classes.text}>{ t('form.password-hint.one-lowercase-character') }</Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.alignIcons}>
          <Grid item>
            <Grid className={classes.smallIcon} item>
              { hasUppercaseCharacter
                ? (
                  <YesIcon />
                  )
                : (
                  <WrongIcon />
                  ) }
            </Grid>
          </Grid>
          <Grid item>
            <Typography className={classes.text}>{ t('form.password-hint.one-uppercase-character') }</Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.alignIcons}>
          <Grid item>
            <Grid className={classes.smallIcon} item>
              { hasNumber
                ? (
                  <YesIcon />
                  )
                : (
                  <WrongIcon />
                  ) }
            </Grid>
          </Grid>
          <Grid item>
            <Typography className={classes.text}>{ t('form.password-hint.one-number') }</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container className={classes.alignIcons}>
          <Grid item>
            <Grid className={classes.smallIcon} item>
              { hasSpecialCharacter
                ? (
                  <YesIcon />
                  )
                : (
                  <WrongIcon />
                  ) }
            </Grid>
          </Grid>
          <Grid item>
            <Typography className={classes.text}>{ t('form.password-hint.one-special-character') }</Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.alignIcons}>
          <Grid item>
            <Grid className={classes.smallIcon} item>
              { hasMinimum10Characters
                ? (
                  <YesIcon />
                  )
                : (
                  <WrongIcon />
                  ) }
            </Grid>
          </Grid>
          <Grid item>
            <Typography className={classes.text}>{ t('form.password-hint.min-length') }</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PasswordHintFormik
