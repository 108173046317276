import { useTranslation } from 'react-i18next'
import DiscountLine from '../cart-lines/DiscountLine'
import ItemFrequencyLine from '../cart-lines/ItemFrequencyLine'
import LineTitle from 'spypoint/src/checkout-module/order/ui/cart-lines/LineTitle'

const PhotoTransmissionCartPart = ({ product }) => {
  const { t } = useTranslation()
  return (
    <>
      <LineTitle full bold>
        { t('app:checkout_process.photo_transmission_plan.title',
          { planType: product.productName }) }
      </LineTitle>
      <LineTitle>{ t('app:checkout_process.device') }</LineTitle>
      <LineTitle align="right" overflow="hidden" bold> { product.cameraName }</LineTitle>
      <LineTitle>
        { t('app:checkout_process.photo_transmission_plan.payment_frequency') }
      </LineTitle>
      <ItemFrequencyLine product={product} />
      { product.includedInInsidersClub &&
        <DiscountLine label={t('app:checkout.included_with_club')} /> }
    </>
  )
}
export default PhotoTransmissionCartPart
