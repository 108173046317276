import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import WarningIcon from 'assets/icons/navigation/WarningIcon'
import ExclamationMessageIcon from 'spypoint/src/assets/icons/snackbar-message/ExclamationMessageIcon'
import NotificationArrow from 'assets/icons/NotificationArrow'

export const MESSAGE_LEVEL = {
  warning: 'warning',
  error: 'error',
}

const useStyles = makeStyles((theme) => ({
  cameraMessage: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '0.5rem',
    padding: '0.5rem 1rem',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: '600',
    fontFamily: theme.typography.caption1?.fontFamily,
    fontSize: theme.typography.caption1?.fontSize,
  },
  message: {
    paddingBottom: '0.25rem',
    color: theme.palette.text.primary,
    fontFamily: theme.typography.caption1?.fontFamily,
    fontSize: theme.typography.caption1?.fontSize,
  },
  icon: {
    '& > svg': {
      width: '24px',
      height: '24px',
    },
  },
  displayBlock: {
    '& .MuiLink-underlineAlways': {
      textDecoration: 'none !important',
    },
  },
  justifyBoxLeft: {
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
  },
  link: {
    '&.MuiLink-root': {
      color: theme.palette.text.primary,
      fontFamily: theme.typography.caption1?.fontFamily,
      fontSize: theme.typography.caption1?.fontSize,
    },
  },
  linkIcon: {
    width: 9,
    marginLeft: 9,
  },
  [MESSAGE_LEVEL.warning]: {
    background: theme.palette.status?.background?.warning,
    border: `1px solid ${theme.palette.status?.warning}`,
    borderRadius: '0.375rem',
  },
  [MESSAGE_LEVEL.error]: {
    background: theme.palette.status?.background?.error,
    border: `1px solid ${theme.palette.status?.error}`,
    borderRadius: '0.375rem',
  },
}))

const CameraMessage = ({ title, subtitle, message, showNotificationArrow, children, onClick, severity = MESSAGE_LEVEL.warning }) => {
  const classes = useStyles()
  const getClassName = () => MESSAGE_LEVEL[severity] || MESSAGE_LEVEL.warning

  const iconByMessageType = {
    warning: <ExclamationMessageIcon />,
    error: <WarningIcon />,
  }

  return (
    <Box
      container
      onClick={onClick}
      wrap="nowrap"
      style={{ cursor: onClick ? 'pointer' : 'default' }}
      className={[classes.cameraMessage, classes[getClassName()]].join(' ')}
    >
      <Box className={classes.justifyBoxLeft}>
        <Box className={classes.icon} xs={2} style={{ marginRight: '0.625rem' }}>
          { iconByMessageType[severity] }
        </Box>
        <Box>
          { title && (
            <Box item>
              <Typography className={classes.title}>{ title }</Typography>
            </Box>
          ) }
          { title && (
            <Box item>
              <Typography className={classes.message}>{ subtitle }</Typography>
            </Box>
          ) }
          { message && (
            <Box item>
              { ' ' }
              <Typography className={classes.message}>{ message }</Typography>
            </Box>
          ) }
          <Box className={classes.displayBlock} item>
            { children }
          </Box>
        </Box>
      </Box>
      {
        showNotificationArrow &&
          <NotificationArrow className={classes.linkIcon} />
      }
    </Box>
  )
}

export default CameraMessage
