import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import InfoRoundedIcon from '@material-ui/icons/InfoRounded'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { getAllPhotosByCamera, getPhotos, getPhotosFiltered, resetPhotos } from 'photo-module/photos/core/photosActions'
import Observer from 'react-intersection-observer'
import PhotosDay from 'photo-module/photos/ui/photos-gallery/PhotosDay'
import Spinner from 'shared-module/components/Spinner'
import usePhotosByDate from 'photo-module/photos/ui/photos-gallery/usePhotosByDate'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import apm from 'shared-module/monitoring/apm'
import ForwardBackLink from 'shared-module/components/FowardBackLink'
import VoskerSecondaryButton from 'spypoint/src/components/buttons/VoskerSecondaryButton'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import WarningIcon from 'assets/icons/navigation/WarningIcon'
import CTypography from 'storybook-component-module/src/components/typographies/CTypography'
import { useTheme } from '@material-ui/core'
import useMediaQuery from '@mui/material/useMediaQuery'
import CButton from 'storybook-component-module/src/components/buttons/CButton'

const useStyles = makeStyles(theme => ({
  textmessage: {
    margin: 'auto',
    color: theme.palette.background.filter,
    textAlign: 'center',
  },
  noPlanContainer: {
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '250px',
    },
  },
  infoIcon: {
    fill: theme.palette?.status?.warning + ' !important',
    width: '1rem',
    top: '0.375rem',
    position: 'relative',
    marginRight: '0.313rem',
  },
  noPlanTextTitle: {
    fontWeight: 600,
  },
  noPlanTextContent: {
    color: theme.palette.text.secondary,
    marginTop: '4px',
    fontWeight: 400,
  },
  noPlanButton: {
    width: 'max-content',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '16px',
    },
  },
  warningIcon: {
    width: '1rem',
    top: '0.375rem',
    position: 'relative',
    marginRight: '0.313rem',
  },
  scrollableDiv: {
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    maxHeight: 'calc(100vh - 380px)',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'calc(100vh - 412px)',
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 'calc(30vh)',
      maxHeight: 'calc(100vh - 280px)',
    },
  },
}))

const BlockPhotoGallery = ({ photosGroupedByDate, PhotoOverlay, isNewUi = false, setIsIntersecting }) => {
  return photosGroupedByDate.map((photo) => (
    <PhotosDay
      key={photo.photos[photo.photos.length - 1].id}
      date={photo.date}
      photosList={photo.photos}
      PhotoOverlay={PhotoOverlay}
      isNewUi={isNewUi}
      setIsIntersecting={setIsIntersecting}
    />
  ),
  )
}

const PhotoGallery = ({ PhotoOverlay, filters, filtersActions, filterCollectionActions, oldImpl, isNewUi = false }) => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()
  const camera = useSelectedCamera()
  const photosByDates = usePhotosByDate(camera?.id)
  const breakpoint = !useMediaQuery(theme.breakpoints.down('xs'))

  const [isIntersecting, setIsIntersecting] = useState(false)
  const { fetching: isPhotosFetching, allPhotosLoaded, photos } = useSelector(state => state.galleryPhoto)

  const hasPlan = camera?.subscription?.plan?.id !== 'Empty'
  const checkPhotoReload = (!allPhotosLoaded && isIntersecting && !isPhotosFetching)

  const showLoader = (isPhotosFetching || photos.length === 0) && !allPhotosLoaded
  const isConfirmActivationPath = location.state && location.state.ActivationPath
  const shared = JSON.parse(localStorage.getItem('isShared'))

  useEffect(() => {
    if (filtersActions) {
      filtersActions.setCamerasFilter([camera?.id])
    }
    apm.log('photo-gallery', [{ cameraModel: camera?.status.model }])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camera])

  useEffect(() => {
    dispatch(resetPhotos())
  }, [filters, dispatch])

  useEffect(() => {
    if (filtersActions && checkPhotoReload) {
      dispatch(getPhotos(filters, shared))
    }
    if (filterCollectionActions && checkPhotoReload) {
      if (oldImpl) {
        dispatch(getPhotosFiltered(filters, shared))
      } else {
        dispatch(getAllPhotosByCamera(camera, filters))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photos, checkPhotoReload, dispatch, camera, filters])
  const reloadPhotos = () => {
    dispatch(resetPhotos())
    if (filtersActions) {
      dispatch(getPhotos(filters, shared))
    }
    if (filterCollectionActions) {
      if (oldImpl) {
        dispatch(getPhotosFiltered(filters, shared))
      } else {
        dispatch(getAllPhotosByCamera(camera, filters))
      }
    }
  }

  const observerOnChange = (isVisible) => setIsIntersecting(isVisible)

  const renderNoPhotosMessage = (isNewUI) => {
    if (!allPhotosLoaded || photos.length > 0) {
      return null
    }

    const noFilteredPhoto = (<p>{ t('photo_gallery.no_filtered_photos') }</p>)
    const cameraNotUpdated = !camera?.status?.installDate
    const isFlexM = camera?.status.model === 'FLEX-M'
    const messageKey = 'photo_gallery.not_connected_legacy'

    if (!isNewUI && cameraNotUpdated) {
      return (
        <>
          <Grid container display="flex" justifyContent="center">
            <Grid item md={6} xs={12}>
              <CTypography variant="body1" color="textPrimary" className={classes.noPlanTextTitle}>
                <Trans i18nKey={messageKey} />
              </CTypography>
              <CTypography variant="body2" className={classes.noPlanTextContent}>
                { isFlexM && (<span role="img" aria-label="warning">⚠️</span>) }
                <Trans i18nKey={`${messageKey}_body`} />
              </CTypography>
              <CTypography variant="body2" className={classes.noPlanTextContent}>
                { isFlexM && (<span role="img" aria-label="lightbul">💡</span>) }
                <Trans i18nKey={`${messageKey}_body_2`} />
              </CTypography>
            </Grid>
          </Grid>
          <Grid container display="flex" justifyContent="center" style={{ marginBottom: breakpoint ? '72px' : undefined, marginTop: '24px' }}>
            <CButton id={camera.id} color="primary" variety="outlined" className={classes.buttonLabel} onClick={reloadPhotos}>
              { t('app:camera.settings.refresh') }
            </CButton>
          </Grid>
        </>
      )
    }
    const noPhoto = isNewUI
      ? (
        <Grid container display="flex" justifyContent="center" style={{ marginBottom: breakpoint ? '72px' : undefined }}>
          <Grid item md={6} xs={12}>
            <CTypography variant="body1" color="textPrimary" className={classes.noPlanTextTitle}><InfoRoundedIcon className={classes.infoIcon} />{ camera.isCellular ? t('photo_gallery.no_photos', { cameraName: camera.config.name }) : t('photo_gallery.no_photos_no_cellular') }</CTypography>
            <CTypography variant="body2" className={classes.noPlanTextContent}>{ camera.isCellular ? t('photo_gallery.is_cellular') : t('photo_gallery.is_not_cellular') }</CTypography>
          </Grid>
        </Grid>
        )
      : (
        <>
          <p>{ camera.isCellular ? t('photo_gallery.no_photos', { cameraName: camera.config.name }) : t('photo_gallery.no_photos_no_cellular') }</p>
          <p>{ camera.isCellular ? t('photo_gallery.is_cellular') : t('photo_gallery.is_not_cellular') }</p>
        </>
        )
    const textToShow = isConfirmActivationPath
      ? (
        <div>
          <p>
            { camera.isCellular
              ? t('photo_gallery.no_photos', { cameraName: camera.config.name })
              : t('photo_gallery.no_photos_no_cellular') }
          </p>
          <p>
            { camera.isCellular
              ? t('photo_gallery.is_cellular_new_activation')
              : t('photo_gallery.is_not_cellular') }
          </p>
        </div>
        )
      : (
          noPhoto)
    return (
      <div className={classes.textmessage}>
        { (filtersActions && filtersActions.selected(filters)) || (filterCollectionActions && filters.length > 0)
          ? noFilteredPhoto
          : textToShow }
      </div>
    )
  }

  if (!hasPlan) {
    return isNewUi
      ? (
        <Box style={{ width: '100%', marginBottom: breakpoint ? '72px' : undefined }}>
          <Box className={classes.noPlanContainer}>
            <CTypography variant="body1" color="textPrimary" style={{ fontWeight: 600 }}><WarningIcon className={classes.warningIcon} /> { t('photo_gallery.plan_expires') }</CTypography>
            <CTypography variant="body2" className={classes.noPlanTextContent}> { t('photo_gallery.no_plan') }</CTypography>
          </Box>
          <Box display="flex" justifyContent="center" mt="24px">
            <VoskerSecondaryButton className={classes.noPlanButton} py={4} onClick={() => history.push(`/camera/${camera.id}/plan`)}>
              { t('photo_gallery.choose_plan_button') }
            </VoskerSecondaryButton>
          </Box>
        </Box>
        )
      : (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <p>
            { `${t('photo_gallery.plan_expires')}${t('photo_gallery.no_plan')}` }
          </p>
          <p>
            <ForwardBackLink pathname={`/camera/${camera.id}/plan`}>
              { t('photo_gallery.choose_plan_button') }
            </ForwardBackLink>
          </p>
        </div>
        )
  }

  return (
    <div className={isNewUi && !(allPhotosLoaded && photos.length === 0) ? classes.scrollableDiv : ''} style={{ textAlign: 'center', width: '100%' }}>
      { renderNoPhotosMessage(isNewUi) }
      <BlockPhotoGallery hasPlan={hasPlan} photosGroupedByDate={photosByDates} PhotoOverlay={PhotoOverlay} isNewUi={isNewUi} setIsIntersecting={setIsIntersecting} />
      <Observer onChange={observerOnChange}>
        { showLoader && <Spinner spinnerMarginTop="10px" position="relative" /> }
      </Observer>
    </div>
  )
}

export default PhotoGallery
