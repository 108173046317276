import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'
import { VoskerInputStyles } from 'spypoint/src/components/buttons/VoskerInputField'
import { useTranslation } from 'react-i18next'
import VoskerSecondaryButton from 'spypoint/src/components/buttons/VoskerSecondaryButton'
import CButton from 'storybook-component-module/src/components/buttons/CButton'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
import Sad from 'spypoint/public/images/sad.png'
import PromotionsIcon from 'assets/icons/navigation/PromotionsIcon'
import ProductsIcon from 'assets/icons/navigation/ProductsIcon'
import TipsIcon from 'assets/icons/navigation/TipsIcon'
import MoreIcon from 'assets/icons/navigation/MoreIcon'
import NewsLetter from 'spypoint/public/images/NewsLetter.png'
import { Box } from '@material-ui/core'
import PreferencesButton from 'user-module/user/ui/register/components/PreferencesButton'

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '432px',
    padding: '32px',
    backgroundColor: '#1F1E1E',
    margin: 'auto',
    borderRadius: ({ isNewUi }) => isNewUi ? '8px' : 0,
  },
  imageHelper: {
    textAlign: 'center',
    marginTop: '6px',
  },
  promotions: {
    backgroundColor: '#313030',
    borderRadius: 6,
    marginBottom: '32px',
  },
  label: {
    marginTop: 10,
  },
  link: {
    color: `${theme.palette.status?.info}`,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  input: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background?.paper,
      paddingRight: 0,
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      marginBottom: '0.25rem',
    },
  },
  textContent: {
    textAlign: 'left',
    fontSize: 24,
    fontWeight: 500,
    fontFamily: 'oswald-normal',
    marginBottom: ({ isNewUi }) => isNewUi ? '16px' : '24px',
    color: theme.palette.text.primary,
  },
  text: {
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'ibm-plex-sans-regular',
    marginBottom: '16px',
    color: theme.palette.text.primary,
  },
  title: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 700,
    fontFamily: 'ibm-plex-sans-regular',
    color: theme.palette.text.primary,
  },
  subTitle: {
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'ibm-plex-sans-regular',
    marginBottom: '35px',
    color: theme.palette.text.primary,
  },
  content: {
    overflowY: 'hidden !important',
  },
  contentSubtitle: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'ibm-plex-sans-regular',
    color: theme.palette.text.primary,
  },
}))

const PreferencesDialog = ({ onClose, isNewUi = false, setOpenConfirmation }) => {
  const theme = useTheme()
  const classes = useStyles({ isNewUi })
  const { t } = useTranslation()
  const [checked, setChecked] = useState(false)
  const [checkedEmail, setCheckedEmail] = useState(false)
  const breakpoint = useMediaQuery(theme.breakpoints.only('xs'))
  const LogoutButton = isNewUi ? VoskerSecondaryButton : CButton

  return (
    <Dialog
      open
      onClose={onClose}
      disableBackdropClick
      PaperProps={{
        className: classes.dialog,
      }}
    >
      <Box component="div" className={classes.imageHelper}>
        <img src={NewsLetter} className={classes.image} />
      </Box>
      <div style={{ alignSelf: 'left', textAlign: 'left' }}>
        <div className={classes.textContent}>
          { t('preferences.title') }
        </div>
        <div className={classes.text}>
          { t('preferences.subtitle') }
        </div>
      </div>
      <div className={classes.promotions}>
        <div style={{ display: 'flex', height: '100%', marginTop: 20 }}>
          <div style={{
            display: 'flex',
            height: 'auto',
            alignItems: 'center',
          }}
          >
            <PromotionsIcon style={{ marginRight: 20, marginLeft: 20, height: 20, width: 20 }} />
          </div>
          <div><div className={classes.title}>{ t('newsLetter2.content.list1.title') }</div><div className={classes.contentSubtitle}>{ t('newsLetter2.content.list1.subtitle') }</div></div>
        </div>
        <div style={{ display: 'flex', height: '100%', marginTop: 20, marginBottom: 20 }}>
          <div style={{
            display: 'flex',
            height: 'auto',
            alignItems: 'center',
          }}
          >
            <ProductsIcon style={{ marginRight: 20, marginLeft: 20, height: 20, width: 20 }} />
          </div>
          <div><div className={classes.title}>{ t('newsLetter2.content.list2.title') }</div><div className={classes.contentSubtitle}>{ t('newsLetter2.content.list2.subtitle') }</div></div>
        </div>
        <div style={{ display: 'flex', height: '100%', marginTop: 20, marginBottom: 20 }}>
          <div style={{
            display: 'flex',
            height: 'auto',
            alignItems: 'center',
          }}
          >
            <TipsIcon style={{ marginRight: 20, marginLeft: 20, height: 20, width: 20 }} />
          </div>
          <div><div className={classes.title}>{ t('newsLetter2.content.list3.title') }</div><div className={classes.contentSubtitle}>{ t('newsLetter2.content.list3.subtitle') }</div></div>
        </div>
        <div style={{ display: 'flex', height: '100%', marginTop: 20, marginBottom: 20 }}>
          <div style={{
            display: 'flex',
            height: 'auto',
            alignItems: 'center',
          }}
          >
            <MoreIcon style={{ marginRight: 20, marginLeft: 20, height: 20, width: 20 }} />
          </div>
          <div><div className={classes.title}>{ t('newsLetter2.content.list4.title') }</div></div>
        </div>
      </div>
      { breakpoint
        ? (
          <Grid container justifyContent="flex-end">
            <Grid xs={12} item style={{ marginBottom: isNewUi ? '8px' : '24px' }}>
              <LogoutButton color="primary" onClick={() => { setOpenConfirmation(true); onClose() }} variety="secondary" style={{ width: '100%' }}>
                { t('preferences.secondaryButton') }
              </LogoutButton>
            </Grid>
            <Grid xs={12} item>
              <PreferencesButton isNewUi={isNewUi} checked={checked} checkedEmail={checkedEmail} onClose={onClose} />
            </Grid>
          </Grid>
          )
        : (
          <Grid container justifyContent="flex-end">
            <Grid item style={{ marginRight: isNewUi ? '8px' : '24px' }}>
              <LogoutButton color="primary" onClick={() => { setOpenConfirmation(true); onClose() }} variety="secondary" style={{ width: '100%' }}>
                { t('preferences.secondaryButton') }
              </LogoutButton>
            </Grid>
            <Grid item>
              <PreferencesButton isNewUi={isNewUi} checked={checked} onClose={onClose} />
            </Grid>
          </Grid>
          ) }
    </Dialog>
  )
}

export default PreferencesDialog
