import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import SharingIcon from 'assets/icons/navigation/SharingIcon'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    marginTop: '15px',
  },
  textBold: {
    fontFamily: 'ibm-plex-sans-regular',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '21px',
    textAlign: 'left',
    color: '#D3CFCF',
    width: '100%',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& .MuiSvgIcon-root': {
      height: '14px !important',
      width: '22px !important',
    },
  },
}))
const CameraDisclaimer = ({ cameras, cameraIds }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const filtredCameras = cameras?.filter((camera) => camera?.status?.lastUpdate !== undefined && camera?.isCellular)
  return (
    <>
      { filtredCameras && <div className={classes.container}><div className={classes.textBold}> { t('camera_sharing.form_helper') }</div><div className={classes.icon}><div><SharingIcon /></div>{ `${cameraIds?.length}/${filtredCameras?.length}` }</div></div> }
    </>
  )
}

export default CameraDisclaimer
