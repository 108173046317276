import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import WarningIcon from 'assets/icons/navigation/WarningIcon'
import makeStyles from '@material-ui/core/styles/makeStyles'
import useCameraActions from 'camera-module/camera/core/useCameraActions'
import usePurpose from 'camera-module/camera/core/usePurpose'

const useStyles = makeStyles(theme => ({
  icon: {
    width: '1rem',
    top: '0.375rem',
    position: 'relative',
    marginRight: '0.313rem',
    color: theme.palette.status?.error || theme.palette.error?.main,
  },
}))

const ShareHistorySelect = ({ historyControl, setHistoryControl, disabled }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const historyTypes = [
    {
      name: t('camera_sharing.options.all'),
      value: 0,
    },
    {
      name: t('camera_sharing.options.Custom'),
      value: 1,
    },
    {
      name: t('camera_sharing.options.starting_today'),
      value: 2,
    },
  ]

  const renderHistoryTypesRow = (historyType) => {
    return (
      <MenuItem value={historyType.value} key={historyType}>
        { historyType.name }
      </MenuItem>
    )
  }

  const renderHistoryRows = () => {
    return historyTypes?.map((historyType) => renderHistoryTypesRow(historyType))
  }

  return (
    <FormControl variant="outlined" required fullWidth margin="dense">
      <InputLabel>{ t('camera_sharing.sharing_history') }</InputLabel>
      <Select
        disabled={disabled}
        style={{ height: 55, marginBottom: 18 }}
        value={historyControl}
        label={t('camera_sharing.sharing_history')}
        onChange={event => setHistoryControl(event.target.value)}
      >
        { renderHistoryRows() }
      </Select>
    </FormControl>
  )
}

export default ShareHistorySelect
