import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

import SecondaryButton from 'shared-module/components/button/SecondaryButton'
import PrimaryButton from 'shared-module/components/button/PrimaryButton'
import usePhotosDelete from 'photo-module/photos/ui/photos-gallery/usePhotosDelete'
import DeleteIcon from 'assets/icons/photo/DeleteIcon'
import VoskerSecondaryButton from 'spypoint/src/components/buttons/VoskerSecondaryButton'
import VoskerPrimaryButton from 'spypoint/src/components/buttons/VoskerPrimaryButton'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  button: {
    height: '2.5rem',
    minHeight: '2.5rem',
  },
  selectedItem: {
    margin: '0 1rem',
    lineHeight: '1.3125rem',
    color: theme.palette.text.secondary,
  },
}))

const DeleteMultiplePhotos = ({ handleDelete, disabled, isNewUi = false }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const shared = JSON.parse(localStorage.getItem('isShared'))
  const { multiSelectEnabled, toggleMultiSelect, photoIdsToDelete, photosToDeleteActions } = usePhotosDelete()

  const toggleActive = () => {
    toggleMultiSelect()
    photosToDeleteActions.reset()
  }

  const renderSelectButton = () => {
    return isNewUi
      ? (
        <Grid container display="flex" flexDirection="row" justifyContent="flex-end" spacing={1} style={{ marginBottom: '1rem' }}>
          <Grid item>
            <VoskerSecondaryButton
              size="large"
              id="activeMultiDelete"
              onClick={toggleActive}
              className={classes.button}
              disabled={disabled}
            >
              { t('button.select') }
            </VoskerSecondaryButton>
          </Grid>
        </Grid>
        )
      : (
        <Button disabled={shared} id="activeMultiDelete" color="default" onClick={toggleActive}>
          <DeleteIcon style={{ height: '25px', width: '25px', marginRight: '5px' }} />
          { t('button.select') }
        </Button>
        )
  }

  const renderActionButtons = () => {
    return isNewUi
      ? (
        <Grid container display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end" spacing={1} style={{ marginBottom: '1rem' }}>
          <Grid item>
            { photoIdsToDelete?.length !== 0 &&
              <Typography variant="body1" className={classes.selectedItem}> { t('app:photo.counter', { count: photoIdsToDelete?.length }) }</Typography> }
          </Grid>

          <Grid item>
            <VoskerSecondaryButton
              id="cancelButton"
              onClick={toggleActive}
              size="large"
              className={classes.button}
            >
              { t('button.cancel') }
            </VoskerSecondaryButton>
          </Grid>
          <Grid item>
            <VoskerPrimaryButton
              id="deleteButton"
              disabled={photoIdsToDelete.length === 0}
              onClick={() => handleDelete()}
              size="large"
              className={classes.button}
            >
              { t('button.delete') }
            </VoskerPrimaryButton>
          </Grid>
        </Grid>
        )
      : (
        <>
          <SecondaryButton
            id="cancelButton"
            onClick={toggleActive}
            style={{ marginRight: 5 }}
            value={t('button.cancel')}
          />
          <PrimaryButton
            id="deleteButton"
            disabled={photoIdsToDelete.length === 0}
            onClick={() => handleDelete()}
            value={t('button.delete')}
          />
        </>
        )
  }

  return multiSelectEnabled
    ? <>{ renderActionButtons() }</>
    : <>{ renderSelectButton() }</>
}

export default DeleteMultiplePhotos
