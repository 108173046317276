import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const LinkIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon viewBox="0 0 18 18" {...props} data-testid="WarningIcon">
      <path d="M1.66678 17.3331L0.202385 15.8688L7.06244 9.0087L0.193494 2.13976L1.66678 0.666474L9.07419 8.07388C9.31973 8.31943 9.4577 8.65251 9.45771 8.99981C9.45773 9.34709 9.31974 9.68018 9.07419 9.92574L1.66678 17.3331Z" fill="#F9B643" />
    </SvgIcon>
  )
}

export default LinkIcon
