import Drawer from '@material-ui/core/Drawer'
import Box from '@material-ui/core/Box'
import Badge from '@material-ui/core/Badge'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { alpha } from '@material-ui/core/styles/colorManipulator'
import { useTheme } from '@material-ui/core'
import useMediaQuery from '@mui/material/useMediaQuery'
import CloseIcon from '@material-ui/icons/Close'

import { useNbUnreadNotifications, useNotifications, useNotificationsActions } from 'notifications-module/core/notifications.hooks'
import NotificationItem from './NotificationItem'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCameras } from 'camera-module/cameras/core/useCameras'
import SpypointNotificationItem from './SpypointNotificationItem'

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    background: alpha(theme.palette.background.default, 0.92),
    overflow: 'hidden',
    top: 64,
    bottom: 0,
    height: 'auto',
    boxShadow: `-7px 1px 24px -10px ${theme.palette.background.pureBlack}`,
    '@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
      background: alpha(theme.palette.background.default, 0.75),
      backdropFilter: 'blur(5px)',
    },
    [theme.breakpoints.down('xs')]: {
      top: 48,
      width: '-webkit-fill-available',
    },
  },
  header: {
    fontSize: '1.125rem',
    fontFamily: theme.typography.fontFamilyBold,
    textTransform: 'uppercase',
  },
  spypointHeader: {
    fontSize: '1.125rem',
    fontFamily: theme.typography.fontFamilyTabs,
    textTransform: 'Capitalize',
  },
  notificationsList: {
    overflow: 'auto',
    overflowX: 'hidden',
    height: 'calc(100vh - 124px)',
    scrollbarWidth: 'thin',
    scrollbarColor: `${theme.palette.background.paper} ${theme.palette.background.default}`,
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.background.default,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.background.paper,
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 108px)',
    },
  },
  button: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  emptyMessageContainer: {
    borderBottom: `solid 1px ${theme.palette.background.paper}`,
    cursor: 'pointer',
    height: '5rem',
  },
}))

const NotificationsList = ({ open, onClose, isNewUi }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [expandedNotificationId, setExpandedNotificationId] = useState(null)
  const notifications = useNotifications()
  const nbUnreadNotifications = useNbUnreadNotifications()
  const actions = useNotificationsActions()
  const theme = useTheme()
  const breakpoint = useMediaQuery(theme.breakpoints.down('xs'))
  const cameraList = useCameras()
  const sortedNotifications = [].concat(notifications).reverse()
  const lastMonth = new Date()
  lastMonth.setMonth(lastMonth.getMonth() - 1)
  const filteredItems = sortedNotifications.filter(item => {
    const itemDate = new Date(item.timeSinceCreation)
    return itemDate >= lastMonth
  })
  useEffect(() => {
    if (notifications.length > 0) { actions.sortNotification(notifications) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nbUnreadNotifications])

  return (
    <Drawer open={open} onClose={onClose} anchor="right" classes={{ paper: classes.drawerPaper }} BackdropProps={{ invisible: true }}>
      <Box width={360}>

        <Box height={60} borderBottom={`solid 1px ${theme.palette.background.paper}`} paddingLeft={3} paddingRight={isNewUi ? 3 : 0} py={2} display="flex" justifyContent="space-between" alignItems="center">
          <Typography className={isNewUi ? classes.header : classes.spypointHeader}>
            { t('app:notificationCenter.title') }{ nbUnreadNotifications ? `(${nbUnreadNotifications})` : '' }
          </Typography>
          <CloseIcon onClick={onClose} className={classes.button} />
        </Box>
      </Box>
      { notifications.length !== 0
        ? (
          <Box className={classes.notificationsList}>
            { filteredItems.map(notification => isNewUi
              ? <NotificationItem key={notification.id} {...notification} cameraList={cameraList} onClose={onClose} />

              : (
                <SpypointNotificationItem
                  expandedNotificationId={expandedNotificationId}
                  setExpandedNotificationId={setExpandedNotificationId}
                  key={notification.id}
                  {...notification}
                  cameraList={cameraList}
                  onClose={onClose}
                />
                )) }
          </Box>
          )
        : (
          <Box display="flex" alignItems="center" py={2} className={classes.emptyMessageContainer}>
            <Box px={2.5} />
            <Box flex={1} width={breakpoint ? 150 : 'max-content'}>
              <Typography>{ t('app:notificationCenter.empty_message') }</Typography>
            </Box>
            <Box px={3.5} />
          </Box>
          ) }

    </Drawer>
  )
}

export default NotificationsList
