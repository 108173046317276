import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import FormControl from '@material-ui/core/FormControl'
import { useFormData } from 'shared-module/new-components/form/FormDataContext'
import useUserActions from 'user-module/user/core/useUserActions'
import messageActions from 'shared-module/message/messageActions'
import CButton from 'storybook-component-module/src/components/buttons/CButton'
import VoskerPrimaryButton from 'spypoint/src/components/buttons/VoskerPrimaryButton'
import useUser from '../../../core/useUser'

const NewsLetterButton = ({ isNewUi = false, checked, onClose, checkedEmail, setIsOpenNewsLetterConfirmationDialog }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const SubmitButton = isNewUi ? VoskerPrimaryButton : CButton

  return (
    <FormControl style={{ width: '100%' }}>
      <SubmitButton id="consent_button" onClick={onClose} color="primary" variety="primary">
        { t('newsLetterConfirmation.button') }
      </SubmitButton>
    </FormControl>
  )
}

export default NewsLetterButton
