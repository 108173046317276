import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import AutoRenewal from 'spypoint/src/user-module/club-membership/ui/header/AutoRenewal'
import BillingCycleEndDate from 'spypoint/src/user-module/club-membership/ui/header/BillingCycleEndDate'
import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'
import { useECommerceFlag } from 'spypoint/src/ecommerce-module/hooks/UseEcommerceFlag'
import { useLoadUserDefaultPaymentInfo } from 'spypoint/src/user-module/payment-methods/core/userPaymentMethods.hooks'
import RedirectToSubscriptionManagement from 'spypoint/src/ecommerce-module/ui/RedirectToSubscriptionManagement.js'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '0.75rem',
    [theme.breakpoints.only('sm')]: {
      marginLeft: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      paddingTop: '1rem',
      alignItems: 'flex-start',
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
  },
}))

const MembershipRenewalSection = ({ membershipClass, titleClass }) => {
  const userMembership = useUserClubMembership()
  const eCommerceEnabled = useECommerceFlag()
  const paymentInfo = useLoadUserDefaultPaymentInfo()
  const isAvailablePaymentMethod = paymentInfo?.isAvailable
  const classes = useStyles()

  if (userMembership.isMember) {
    return (
      <Grid container spacing={2} className={clsx(classes.container, membershipClass)}>
        { !eCommerceEnabled && <AutoRenewal titleClass={titleClass} isAvailablePaymentMethod={isAvailablePaymentMethod} /> }
        <BillingCycleEndDate />
        { eCommerceEnabled && <RedirectToSubscriptionManagement /> }
      </Grid>
    )
  }

  return null
}

export default MembershipRenewalSection
