const getNotificationList = state => state.notifications?.notifications ?? []

export const getNotifications = state => getNotificationList(state)

export const getNbUnreadNotifications = state => {
  const lastMonth = new Date()
  lastMonth.setMonth(lastMonth.getMonth() - 1)
  const filteredItems = getNotificationList(state).filter(item => {
    const itemDate = new Date(item.timeSinceCreation)
    return itemDate >= lastMonth
  })
  return filteredItems.filter((notification) => !notification.read).length
}

export const getCriticalNotifications = state => {
  return getNotificationList(state).filter((notification) => notification.isSticky)
}
