import { useTranslation } from 'react-i18next'
import Divider from '@material-ui/core/Divider'
import PrivatePageLayout from 'shared-module/new-components/private-page/PrivatePageLayout'
import { FormProvider } from 'shared-module/new-components/form/new/FormContext'
import Title from 'shared-module/new-components/layout/Title'
import SpydollarsBalance from 'user-module/promo-code/ui/redeem-promo-codes/components/SpydollarsBalance'
import RedeemPromoCodesForm from 'user-module/promo-code/ui/redeem-promo-codes/components/form/RedeemPromoCodesForm'
import RedeemPromoCodesSuccess from 'user-module/promo-code/ui/redeem-promo-codes/components/form/RedeemPromoCodesSuccess'
import RedeemPromoCodesInfo from 'user-module/promo-code/ui/redeem-promo-codes/components/RedeemPromoCodesInfo'
import WarningBox from 'camera-module/camera/ui/camera-sharing-page/WarningBox/WarningBox'
import Box from '@material-ui/core/Box'
import WarningIcon from 'assets/icons/WarningIcon'
import { useLDFlags } from 'spypoint/src/launchDarkly-module/launchDarklyFlagsContext'

const RedeemPromoCodesPage = () => {
  const { t } = useTranslation()
  const { flags } = useLDFlags()
  const referralProgramSunsetEnabled = flags && flags['referral-program-sunset']
  const isBannerEnabled = referralProgramSunsetEnabled?.isBannerEnabled

  return (
    <PrivatePageLayout>
      { isBannerEnabled && (
        <Box marginBottom={4}>
          <WarningBox
            Icon={WarningIcon}
            content={t('redeem_promo_code.text')}
            date={t('redeem_promo_code.date')}
          />
        </Box>
      ) }
      <Title mb={4}>{ t('redeem_promo_code.title') }</Title>
      <SpydollarsBalance />
      <FormProvider>
        <RedeemPromoCodesForm />
        <RedeemPromoCodesSuccess />
      </FormProvider>
      <Divider />
      <RedeemPromoCodesInfo />
    </PrivatePageLayout>
  )
}

export default RedeemPromoCodesPage
