import { useHistory } from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import IconButton from '@material-ui/core/IconButton'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core'
import VoskerButtonWrapper from './VoskerButtonWrapper'
import VoskerBackArrowIcon from 'spypoint/src/photos-module/photos/ui/icons/VoskerBackArrowIcon'

const useStyles = makeStyles(theme => ({
  button: {
    '&:hover': {
      backgroundColor: theme.palette.background?.tile,
    },
  },
}))

const BackButton = ({ isNewUi = false }) => {
  const theme = useTheme()
  const classes = useStyles()
  const history = useHistory()
  const camera = useSelectedCamera()

  const returnToGallery = () => history.push(`/camera/${camera.id}`)

  return camera && isNewUi
    ? (
      <VoskerButtonWrapper onClick={returnToGallery} data-testid="back-button">
        <VoskerBackArrowIcon color={theme.palette.basic?.lightGrey} />
      </VoskerButtonWrapper>
      )
    : camera && !isNewUi
      ? (
        <IconButton data-testid="back-button" color="secondary" className={classes.button} onClick={returnToGallery}>
          <ArrowBackIcon style={{ color: theme.palette.text.primary }} />
        </IconButton>
        )
      : null
}

export default BackButton
