import axios from 'axios'
import jwtDecode from 'jwt-decode'
import jwtToken from 'shared-module/token/jwtToken'
import { UNAUTHORIZED } from 'shared-module/api/httpStatusCode'

const restapi = axios.create()
export const eCommerceTokenID = 'salesForceToken'
export const eCommerceRefreshTokenID = 'salesForceRefreshToken'
export const eCommerceLDFlag = 'eCommerceLDFlag'
export const eCommerceUnreachable = 'not_available'

restapi.interceptors.request.use(
  config => {
    config.baseURL = window.location.origin.replace('webapp', 'restapi')
    config.headers.post['Content-Type'] = 'application/json'

    const token = jwtToken.get()
    if (token) {
      config.headers.Authorization = `bearer ${token}`
    }
    const RECAPTCHA_VERSION = localStorage.getItem('recaptcha_version')
    if (RECAPTCHA_VERSION) config.headers['x-recaptcha-version'] = RECAPTCHA_VERSION
    const RECAPTCHA_TOKEN = localStorage.getItem('recaptcha_token')
    if (RECAPTCHA_TOKEN) config.headers['x-recaptcha-token'] = RECAPTCHA_TOKEN
    return config
  },
  error => {
    return Promise.reject(error)
  },
)

let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

restapi.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config
    const originalResponse = error.response.data
    const baseURL = window.location.origin.replace('webapp', 'restapi')
    const eCommerceEnabled = localStorage.getItem('eCommerceLDFlag')

    const isExternalURL =
      window.location.pathname === '/login' ||
      window.location.pathname === '/register' ||
      window.location.pathname === '/reset-password' ||
      window.location.pathname === '/download-app'

    // Intercepts 401 error calls
    if (error.response.status === UNAUTHORIZED && !originalRequest._retry && !isExternalURL) {
      // If a call is already being processed, the other calls are queued to be run after the refresh is successful
      if (isRefreshing) {
        return new Promise((resolve, reject) => { failedQueue.push({ resolve, reject }) })
          .then(token => axios({
            method: originalRequest.method,
            baseURL: baseURL,
            url: originalRequest.url,
            headers: eCommerceEnabled
              ? {
                  ...originalRequest.headers,
                  Authorization: `bearer ${token?.token}`,
                  'x-commerce-token': token?.sfccToken,
                }
              : {
                  ...originalRequest.headers,
                  Authorization: `bearer ${token?.token}`,
                },
          }).then(response => response))
          .catch(err => Promise.reject(err))
      }

      isRefreshing = true
      originalRequest._retry = true
      const refresh = jwtToken.getRefresh()
      const deviceID = jwtToken.getDevice()
      const commerceRefreshToken = localStorage.getItem(eCommerceRefreshTokenID)

      // Refreshes the WEBAPP token only if it is expired (avoid triggering the refresh on e-commerce token expiration)
      if (!commerceRefreshToken && refresh && !originalResponse.type) {
        try {
          await axios({
            method: 'post',
            baseURL: baseURL,
            url: '/api/v3/user/refreshtoken',
            data: { deviceID, refresh },
            withCredentials: true, // Include credentials (cookies) for this request
          })
            .then(async response => {
              await jwtToken.set(response.data.token)
              await jwtToken.setRefresh(response.data.refresh)
            })
        } catch (error) {
          console.error('Failed', error)
          throw error
        }
      }

      if (commerceRefreshToken && refresh && !originalResponse.type) {
        try {
          return await axios({
            method: 'post',
            baseURL: baseURL,
            url: '/api/v3/user/refreshtoken',
            data: { deviceID, refresh },
            withCredentials: true, // Include credentials (cookies) for this request
          })
            .then(async response => {
              await jwtToken.set(response.data.token)
              await jwtToken.setRefresh(response.data.refresh)
              processQueue(null, { token: jwtToken.get(), sfccToken: null })

              /* If the WEBAPP token is expired and the user refreshes the page:
               *  ERROR CASE --> The user gets logged out since the userId is not accessible through the expired token
               *
               *  SOLUTION --> To prevent such behavior, we are intercepting the call were the route indicates that
               *  the user ID is undefined and replace it with the newly generate user id stored in the new token.
              */
              if (originalRequest.url === '/api/v3/user/undefined') {
                const newToken = jwtDecode(jwtToken.get()).user._id
                originalRequest.url = `/api/v3/user/${newToken}`
              }

              return axios({
                method: originalRequest.method,
                baseURL: baseURL,
                url: originalRequest.url,
                headers: {
                  ...originalRequest.headers,
                  Authorization: `bearer ${jwtToken.get()}`,
                  'x-commerce-token': localStorage.getItem(eCommerceTokenID),
                },
              })
                .then(response => response)
                .finally(() => { isRefreshing = false })
            })
        } catch (error) {
          console.error('Failed', error)
          throw error
        }
      }

      // E-commerce Refresh Token Mechanism
      if (commerceRefreshToken) {
        // Refreshes e-commerce token
        try {
          return await axios({
            method: 'post',
            baseURL: baseURL,
            url: '/api/v3/commerce/user/token/refresh',
            data: { commerce_refresh_token: commerceRefreshToken },
            headers: { Authorization: `bearer ${jwtToken.get()}` },
            withCredentials: true, // Include credentials (cookies) for this request
          }).then(async response => {
            await localStorage.setItem(eCommerceTokenID, response.data.access_token)
            await localStorage.setItem(eCommerceRefreshTokenID, response.data.refresh_token)
            processQueue(null, { token: jwtToken.get(), sfccToken: response.data.access_token })

            /* If the WEBAPP token is expired and the user refreshes the page:
               *  ERROR CASE --> The user gets logged out since the userId is not accessible through the expired token
               *
               *  SOLUTION --> To prevent such behavior, we are intercepting the call were the route indicates that
               *  the user ID is undefined and replace it with the newly generate user id stored in the new token.
               */
            if (originalRequest.url === '/api/v3/user/undefined') {
              const newToken = jwtDecode(jwtToken.get()).user._id
              originalRequest.url = `/api/v3/user/${newToken}`
            }

            return axios({
              method: originalRequest.method,
              baseURL: baseURL,
              url: originalRequest.url,
              headers: {
                ...originalRequest.headers,
                Authorization: `bearer ${jwtToken.get()}`,
                'x-commerce-token': localStorage.getItem(eCommerceTokenID),
              },
            }).then(response => response)
          }).finally(() => { isRefreshing = false })
        } catch (error) {
          if (localStorage.getItem(eCommerceTokenID) !== eCommerceUnreachable) {
            console.error('Failed', error)
            throw error
          }
        }
      } else {
        // Demands a new e-commerce token
        try {
          return await axios({
            method: 'post',
            baseURL: baseURL,
            url: '/api/v3/commerce/user/token',
            headers: { Authorization: `bearer ${jwtToken.get()}` },
          }).then(async response => {
            await localStorage.setItem(eCommerceTokenID, response.data.access_token)
            await localStorage.setItem(eCommerceRefreshTokenID, response.data.refresh_token)
            processQueue(null, { token: jwtToken.get(), sfccToken: response.data.access_token })

            return axios({
              method: originalRequest.method,
              baseURL: baseURL,
              url: originalRequest.url,
              headers: {
                ...originalRequest.headers,
                Authorization: `bearer ${jwtToken.get()}`,
                'x-commerce-token': localStorage.getItem(eCommerceTokenID),
              },
            }).then(response => response)
          }).finally(() => { isRefreshing = false })
        } catch (error) {
          if (localStorage.getItem(eCommerceTokenID) !== eCommerceUnreachable) {
            console.error('Failed', error)
            throw error
          }
        }
      }
    }
    return Promise.reject(error)
  }, async error => {
    const originalRequest = error.config
    const originalResponse = error.response.data
    const baseURL = window.location.origin.replace('webapp', 'restapi')
    const eCommerceEnabled = localStorage.getItem(eCommerceLDFlag)

    const isExternalURL =
      window.location.pathname === '/login' ||
      window.location.pathname === '/register' ||
      window.location.pathname === '/reset-password' ||
      window.location.pathname === '/download-app'

    // Intercepts 401 error calls
    if (error.response.status === UNAUTHORIZED && !originalRequest._retry && !isExternalURL) {
      // If a call is already being processed, the other calls are queued to be run after the refresh is successful
      if (isRefreshing) {
        return new Promise((resolve, reject) => { failedQueue.push({ resolve, reject }) })
          .then(token => axios({
            method: originalRequest.method,
            baseURL: baseURL,
            url: originalRequest.url,
            headers: eCommerceEnabled
              ? {
                  ...originalRequest.headers,
                  Authorization: `bearer ${token?.token}`,
                  'x-commerce-token': token?.sfccToken,
                }
              : {
                  ...originalRequest.headers,
                  Authorization: `bearer ${token?.token}`,
                },
          }).then(response => response))
          .catch(err => Promise.reject(err))
      }

      isRefreshing = true
      originalRequest._retry = true
      const refresh = jwtToken.getRefresh()
      const deviceID = jwtToken.getDevice()
      const commerceRefreshToken = localStorage.getItem(eCommerceRefreshTokenID)

      // Refreshes the WEBAPP token only if it is expired (avoid triggering the refresh on e-commerce token expiration)
      if (!eCommerceEnabled && refresh && !originalResponse.type) {
        try {
          await axios({
            method: 'post',
            baseURL: baseURL,
            url: '/api/v3/user/refreshtoken',
            data: { deviceID, refresh },
            withCredentials: true, // Include credentials (cookies) for this request
          })
            .then(async response => {
              await jwtToken.set(response.data.token)
              await jwtToken.setRefresh(response.data.refresh)
            })
        } catch (error) {
          console.error('Failed', error)
          throw error
        }
      }

      if (eCommerceEnabled && refresh && !originalResponse.type) {
        try {
          return await axios({
            method: 'post',
            baseURL: baseURL,
            url: '/api/v3/user/refreshtoken',
            data: { deviceID, refresh },
            withCredentials: true, // Include credentials (cookies) for this request
          })
            .then(async response => {
              await jwtToken.set(response.data.token)
              await jwtToken.setRefresh(response.data.refresh)
              processQueue(null, { token: jwtToken.get(), sfccToken: null })

              /* If the WEBAPP token is expired and the user refreshes the page:
               *  ERROR CASE --> The user gets logged out since the userId is not accessible through the expired token
               *
               *  SOLUTION --> To prevent such behavior, we are intercepting the call were the route indicates that
               *  the user ID is undefined and replace it with the newly generate user id stored in the new token.
              */
              if (originalRequest.url === '/api/v3/user/undefined') {
                const newToken = jwtDecode(jwtToken.get()).user._id
                originalRequest.url = `/api/v3/user/${newToken}`
              }

              return axios({
                method: originalRequest.method,
                baseURL: baseURL,
                url: originalRequest.url,
                headers: {
                  ...originalRequest.headers,
                  Authorization: `bearer ${jwtToken.get()}`,
                  'x-commerce-token': localStorage.getItem(eCommerceTokenID),
                },
              })
                .then(response => response)
                .finally(() => { isRefreshing = false })
            })
        } catch (error) {
          console.error('Failed', error)
          throw error
        }
      }

      // E-commerce Refresh Token Mechanism
      if (eCommerceEnabled) {
        if (commerceRefreshToken) {
          // Refreshes e-commerce token
          try {
            return await axios({
              method: 'post',
              baseURL: baseURL,
              url: '/api/v3/commerce/user/token/refresh',
              data: { commerce_refresh_token: commerceRefreshToken },
              headers: { Authorization: `bearer ${jwtToken.get()}` },
              withCredentials: true, // Include credentials (cookies) for this request
            }).then(async response => {
              await localStorage.setItem(eCommerceTokenID, response.data.access_token)
              await localStorage.setItem(eCommerceRefreshTokenID, response.data.refresh_token)
              processQueue(null, { token: jwtToken.get(), sfccToken: response.data.access_token })

              /* If the WEBAPP token is expired and the user refreshes the page:
               *  ERROR CASE --> The user gets logged out since the userId is not accessible through the expired token
               *
               *  SOLUTION --> To prevent such behavior, we are intercepting the call were the route indicates that
               *  the user ID is undefined and replace it with the newly generate user id stored in the new token.
               */
              if (originalRequest.url === '/api/v3/user/undefined') {
                const newToken = jwtDecode(jwtToken.get()).user._id
                originalRequest.url = `/api/v3/user/${newToken}`
              }

              return axios({
                method: originalRequest.method,
                baseURL: baseURL,
                url: originalRequest.url,
                headers: {
                  ...originalRequest.headers,
                  Authorization: `bearer ${jwtToken.get()}`,
                  'x-commerce-token': localStorage.getItem(eCommerceTokenID),
                },
              }).then(response => response)
            }).finally(() => { isRefreshing = false })
          } catch (error) {
            if (localStorage.getItem(eCommerceTokenID) !== eCommerceUnreachable) {
              console.error('Failed', error)
              throw error
            }
          }
        } else {
          // Demands a new e-commerce token
          try {
            return await axios({
              method: 'post',
              baseURL: baseURL,
              url: '/api/v3/commerce/user/token',
              headers: { Authorization: `bearer ${jwtToken.get()}` },
            }).then(async response => {
              await localStorage.setItem(eCommerceTokenID, response.data.access_token)
              await localStorage.setItem(eCommerceRefreshTokenID, response.data.refresh_token)
              processQueue(null, { token: jwtToken.get(), sfccToken: response.data.access_token })

              return axios({
                method: originalRequest.method,
                baseURL: baseURL,
                url: originalRequest.url,
                headers: {
                  ...originalRequest.headers,
                  Authorization: `bearer ${jwtToken.get()}`,
                  'x-commerce-token': localStorage.getItem(eCommerceTokenID),
                },
              }).then(response => response)
            }).finally(() => { isRefreshing = false })
          } catch (error) {
            if (localStorage.getItem(eCommerceTokenID) !== eCommerceUnreachable) {
              console.error('Failed', error)
              throw error
            }
          }
        }
      }
    }
    return Promise.reject(error)
  },
)

export default restapi
