import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'

import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import { apiCameraPlans } from 'camera-module/camera/core/transmission-plan/core/cameraPlans.types'
import useFreeTrialActivationModal from 'camera-module/free-trial-activation-modal/core/useFreeTrialActivationModal'

const MILLISECONDS_IN_A_DAY = 60 * 60 * 24 * 1000

const FreeTrialActivationModal = ({ supportNewUi }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const {
    camera,
    closeFreeTrialActivationModal,
  } = useFreeTrialActivationModal()

  const getCameraTrialDuration = () => {
    if (
      !camera?.subscription?.startDateBillingCycle ||
      !camera?.subscription?.endDateBillingCycle
    ) {
      return 0
    }

    const planDurationInMs = new Date(camera.subscription.endDateBillingCycle) - new Date(camera.subscription.startDateBillingCycle)
    const planDurationInDays = Math.round(planDurationInMs / MILLISECONDS_IN_A_DAY)

    if (planDurationInDays < 0 || planDurationInDays > 30) {
      return 0
    }

    return planDurationInDays
  }

  const trialDuration = getCameraTrialDuration()
  const isEmptyPlan = camera?.subscription?.plan?.name === apiCameraPlans.empty
  const isFreePlan = camera?.subscription?.plan?.name === apiCameraPlans.free

  if (isEmptyPlan || isFreePlan || trialDuration === 0) {
    return null
  }

  const onConfirm = () => {
    history.push(`/camera/${camera.id}/plan`)
    closeFreeTrialActivationModal()
  }

  const renderContent = () => (
    <>
      <Typography>{ t('add_camera.activation_modal.your_camera_is_activated', {
        cameraName: camera?.config?.name,
      }) }
      </Typography>
      <Typography>{ t('add_camera.activation_modal.free_trial_details', {
        duration: trialDuration,
        planName: camera?.subscription?.plan?.name,
      }) }
      </Typography>
    </>
  )

  if (!camera) {
    return null
  }
  return (
    <ConfirmationDialog
      isNewUi={supportNewUi}
      open
      title={t('add_camera.activation_modal.congratulations')}
      text={renderContent()}
      confirmText={t('add_camera.activation_modal.confirm_button')}
      cancelText={t('add_camera.activation_modal.close_button')}
      onConfirm={onConfirm}
      onClose={closeFreeTrialActivationModal}
      onCancel={closeFreeTrialActivationModal}
    />
  )
}

export default FreeTrialActivationModal
