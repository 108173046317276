import restapi from 'shared-module/api/restapi'
import handleError from 'shared-module/api/apiError'
import { buildCameraPlans } from 'camera-module/camera/core/transmission-plan/api/cameraPlansBridge'

const getCameraPlans = (cameraId) =>
  restapi.get(`/api/v3/camera/${cameraId}/plan`)
    .then(response => buildCameraPlans(response.data))
    .catch(handleError)

const cameraPlansApi = { getCameraPlans }
export default cameraPlansApi
