import creditCardBrand from 'spypoint/src/user-module/payment-information/core/creditCardBrand'

const buildPaymentInformation = info => {
  if (paymentInformationIncomplete(info)) return { noCard: true }

  return {
    brand: buildBrand(info.brand),
    expirationMonth: info.expMonth,
    expirationYear: info.expYear,
    last4: info.last4,
  }
}

const paymentInformationIncomplete = info => !(!!info.brand && !!info.expMonth && !!info.expYear && !!info.last4)

const brands = {
  'american express': creditCardBrand.americanExpress,
  'diners club': creditCardBrand.dinersClub,
  discover: creditCardBrand.discover,
  jcb: creditCardBrand.jcb,
  mastercard: creditCardBrand.mastercard,
  unionpay: creditCardBrand.unionpay,
  visa: creditCardBrand.visa,
  unknown: creditCardBrand.unknown,
}

const buildBrand = brand => brands[brand] !== undefined ? brands[brand] : creditCardBrand.unknown

export default buildPaymentInformation
