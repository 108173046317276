import { Component } from 'react'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import Box from '@material-ui/core/Box'
import Switch from '@material-ui/core/Switch'
import withStyles from '@material-ui/core/styles/withStyles'

import { formatDate } from 'shared-module/common-utils'
import SpyDollarsInfo from 'checkout-module/checkout/ui/shared/SpyDollarsInfo'
import { apiCameraPlans } from 'camera-module/camera/core/transmission-plan/core/cameraPlans.types'
import RedirectToSubscriptionManagement from 'spypoint/src/ecommerce-module/ui/RedirectToSubscriptionManagement'

const styles = theme => ({
  cameraCellWarning: {
    marginTop: '15px',
    fontSize: '10pt',
    color: theme.palette.primary.main,
  },
})

class SubscriptionInfos extends Component {
  /**
   * Rendering of auto renew feature.
   *
   * @param {boolean} isAutoRenew - Status of the feature Auto renew.
   * @return {JSX.Element} The auto renew widget.
   */
  renderAutoRenew = (isAutoRenew) => {
    const { t } = this.props

    return (
      <Box style={{ whiteSpace: 'nowrap' }}>
        { t('billing.auto_renew') }
        {
          isAutoRenew
            ? <Switch checked disabled={this.props.disableAutoRenew} color="primary" onChange={() => { this.props.onDisableAutoRenew() }} />
            : <Switch checked={false} disabled={this.props.disableAutoRenew} onChange={() => { this.props.onEnableAutoRenew() }} />
        }
      </Box>
    )
  }

  /**
   * Rendering of the payment status of this subscription.
   *
   * @param {boolean} status - Status of the payment for this subscription.
   * @return {JSX.Element} The payment status of the subscription.
   */
  renderPaymentStatus = (status) => {
    const { t, theme } = this.props

    let color
    let message = 'active'
    switch (status) {
      case 'incomplete':
        color = theme.palette.primary.status
        message = 'pending'
        break
      case 'past_due':
      case 'unpaid':
      case 'canceled':
      case 'error':
      case 'incomplete_expired':
        color = theme.palette.error.main
        message = 'refused'
        break
      default:
        color = theme.palette.text.primary
    }
    return (
      <Box paddingY={1}>
        { t('billing.payment_status.title') }: <span style={{ fontWeight: 'bold', color: color }}>{ t('billing.payment_status.' + message) }</span>
      </Box>
    )
  }

  /**
   * Rendering of the component.
   */
  render () {
    const { classes, t, i18n } = this.props

    let warning = null
    const subscription = this.props.subscription
    const ecommerceEnabled = this.props.ecommerceEnabled
    const plan = subscription.plan
    const isAutoRenew = subscription.isAutoRenew
    let showAutoRenew = true
    const paymentStatus = subscription.paymentStatus
    const isActivePaymentStatus = paymentStatus === 'active'

    if (!plan.isActive) {
      // Old plan
      warning = 'billing.warnings.old_plan'
      showAutoRenew = false
    }
    if (subscription.paymentFrequency === 'one_month' && subscription.isFree) {
      // Free month
      warning = 'billing.warnings.free_month'
      showAutoRenew = false
    }
    if (subscription.paymentFrequency === 'seven_days' && subscription.isFree) {
      // Free month
      warning = 'billing.warnings.free_seven_days'
      showAutoRenew = false
    }
    if (plan.isActive && plan.isFree) {
      // Active Free plan
      showAutoRenew = false
    }
    if (showAutoRenew && !isAutoRenew && !ecommerceEnabled) {
      const isMember = this.props.isMember
      if (isMember) {
        warning = 'billing.warnings.auto_renew_disabled_member'
      } else {
        const hasFreePlan = !!this.props.camera.hasFreePlan
        warning = hasFreePlan ? 'billing.warnings.auto_renew_disabled' : 'billing.warnings.auto_renew_disabled_no_plan'
      }
    }

    return (
      <Box maxWidth={350}>
        { plan.name !== apiCameraPlans.empty && (
          <>
            <Box paddingY={1}>
              { t('billing.current_plan') }: <strong>{ t('billing.plans.' + plan.name).toUpperCase() }</strong>
            </Box>
            <Box paddingY={1}>
              { t('billing.billing') }: <strong>{ t('billing.payment_frequency.' + subscription.paymentFrequency) }</strong>
            </Box>
            <Box paddingY={1}>
              { t('billing.period') }: <strong>{ formatDate(subscription.startDateBillingCycle.toString(), i18n) } - { formatDate(subscription.endDateBillingCycle.toString(), i18n) }</strong>
            </Box>
            { ecommerceEnabled && !isAutoRenew && (
              <Box paddingY={1}>
                { t('app:management.plan_ended.label') }:&nbsp; <strong>{ formatDate(subscription.endDateBillingCycle.toString(), i18n) }</strong>
              </Box>
            ) }

            { ecommerceEnabled && isAutoRenew && (
              <Box paddingY={1}>
                { t('app:management.next_billing.label') }:&nbsp; <strong>{ formatDate(subscription.endDateBillingCycle.toString(), i18n) }</strong>
              </Box>
            ) }
            { showAutoRenew && !ecommerceEnabled && this.renderAutoRenew(isAutoRenew) }
            { !isActivePaymentStatus && this.renderPaymentStatus(paymentStatus) }
            { warning && <Box paddingY={1} className={classes.cameraCellWarning}>* { t(warning) }</Box> }
          </>
        ) }
        { ecommerceEnabled && <Box paddingY={1}> <RedirectToSubscriptionManagement md={12} spacing={0} /> </Box> }
        <SpyDollarsInfo />
      </Box>
    )
  }
}

const mapStateToProps = state => ({
  camera: state.selectedCamera.camera,
  user: state.user,
})

export default compose(
  withStyles(styles),
  withTranslation(),
  connect(mapStateToProps),
)(SubscriptionInfos)
