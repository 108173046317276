import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import messageState from 'shared-module/message/messageState'
import messageActions from 'shared-module/message/messageActions'
import useUserActions from 'user-module/user/core/useUserActions'
import { ERROR } from 'shared-module/message/messageTypes'
import CButton from 'storybook-component-module/src/components/buttons/CButton'
import CCheckbox from 'storybook-component-module/src/components/buttons/CCheckbox'
import webview from 'shared-module/webview/webview'
import VoskerErrorButton from 'spypoint/src/components/buttons/VoskerErrorButton'
import VoskerCheckbox from 'spypoint/src/components/VoskerCheckbox'
import useUser from '../../user/core/useUser'

export const MESSAGE_COUNTDOWN = 5 * 1000

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: 16,
    marginLeft: 30,
  },
  unchecked: {
    fill: 'transparent !important',
    border: `1.5px solid ${theme.palette.checkbox?.primary}`,
  },
}))

const ConfirmationStep = ({ onSubmit, onCancel, onReset, disabled, completed, toggleCompletion, isNewUi }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { deleteUser, logout } = useUserActions()
  const message = useSelector((state) => state.message)
  const user = useUser()
  const { id } = user
  const Button = isNewUi ? VoskerErrorButton : CButton
  const CheckBox = isNewUi ? VoskerCheckbox : CCheckbox
  const disabledColor = isNewUi ? 'textSecondary' : 'secondary'

  const [cancelButtonClicked, setCancelButtonClicked] = useState()
  const [snackbarWasShown, setSnackbarWasShown] = useState(false)

  const closeWebViewHandler = () => {
    if (webview.isWebview()) {
      webview.postMessage({ action: 'close' })
    }
  }

  const closeWebViewForAccountDeletion = () => {
    if (webview.isWebview()) {
      webview.postMessage({ action: 'account-deleted' })
    }
  }

  const handleAction = () => {
    setSnackbarWasShown(true)
    setCancelButtonClicked(false)
    onSubmit && onSubmit()

    dispatch(
      messageActions.showMessage(
        'app:account.management.stepper.last_step.message',
        {
          label: 'button.cancel',
          handleAction: () => {
            toggleCompletion()
            setCancelButtonClicked(true)
            dispatch(messageActions.clear())
            onCancel && onCancel()
            closeWebViewHandler()
          },
        },
        MESSAGE_COUNTDOWN,
      ),
    )
  }

  const deleteUserSubmitAction = () =>
    deleteUser().then((response) => {
      if (response.messageType === ERROR) {
        toggleCompletion()
        setCancelButtonClicked(false)
        onReset && onReset()
      } else {
        closeWebViewForAccountDeletion()
        logout()
      }
    })

  useEffect(() => {
    if (snackbarWasShown && message === messageState.defaultState && !cancelButtonClicked) {
      deleteUserSubmitAction()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snackbarWasShown, message, cancelButtonClicked])

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" mb={isNewUi ? 6 : 1.5}>
      <Box display="flex" flexDirection="row" alignItems="center" ml={2.5} mt={2}>
        <CheckBox checked={completed} onChange={toggleCompletion} disabled={disabled} uncheckedClassName={classes.unchecked} />

        <Typography color={disabled ? disabledColor : 'textPrimary'}>
          { t('app:account.management.stepper.last_step.terms') }
        </Typography>
      </Box>

      <Grid container spacing={0}>
        <Grid item>
          <Button
            variety="primary"
            color="primary"
            onClick={handleAction}
            disabled={!completed || disabled}
            className={classes.button}
          >
            { t('app:account.management.stepper.last_step.action_label') }
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ConfirmationStep
