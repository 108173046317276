import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

import CheckBox from 'spypoint/src/assets/icons/CheckBox'
import UncheckedCheckBox from 'spypoint/src/assets/icons/UncheckedCheckBox'
import CCheckbox from 'storybook-component-module/src/components/buttons/CCheckbox'

const useStyles = makeStyles(theme => ({
  unchecked: {
    color: 'transparent',
    border: `1.5px solid ${theme.palette.border?.contrast}`,
    borderRadius: 5,
  },
  checked: {
    color: theme.palette.status?.info,
    borderRadius: 5,
  },
}))

const VoskerCheckbox = ({ checked, onChange, disabled, checkedClassName, uncheckedClassName }) => {
  const classes = useStyles()
  return (
    <CCheckbox
      icon={<UncheckedCheckBox className={clsx(classes.unchecked, uncheckedClassName)} />}
      checkedIcon={<CheckBox className={clsx(classes.checked, checkedClassName)} />}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      disableRipple
      disabledSx
    />
  )
}

export default VoskerCheckbox
