import { useTranslation } from 'react-i18next'

const ProductRandering = ({ product }) => {
  const { t } = useTranslation()
  function getProductName (name) {
    let productName
    switch (name) {
      case 'D06150':
        productName = t('app:ecommerce_products.D06150')
        break
      case 'D06151':
        productName = t('app:ecommerce_products.D06151')
        break
      case 'D06100':
        productName = t('app:ecommerce_products.D06100')
        break
      case 'D06120':
        productName = t('app:ecommerce_products.D06120')
        break
      case 'D06121':
        productName = t('app:ecommerce_products.D06121')
        break
      case 'D06130':
        productName = t('app:ecommerce_products.D06130')
        break
      case 'D06131':
        productName = t('app:ecommerce_products.D06131')
        break
      case 'D06140':
        productName = t('app:ecommerce_products.D06140')
        break
      case 'D06141':
        productName = t('app:ecommerce_products.D06141')
        break
      default:
        productName = 'Invalid Product Id'
    }
    return productName
  }
  return getProductName(product)
}

export default ProductRandering
