import Battery0NA from 'assets/icons/status/Battery0NA'
import Battery0 from 'assets/icons/status/Battery0'
import Battery1 from 'assets/icons/status/Battery1'
import Battery2 from 'assets/icons/status/Battery2'
import Battery3 from 'assets/icons/status/Battery3'
import Battery4 from 'assets/icons/status/Battery4'
import Battery5 from 'assets/icons/status/Battery5'
import SolarBatteyNA from 'assets/icons/status/SolarBatteyNA'
import SolarBattey0 from 'assets/icons/status/SolarBattey0'
import SolarBattey1 from 'assets/icons/status/SolarBattey1'
import SolarBattey2 from 'assets/icons/status/SolarBattey2'
import SolarBattey3 from 'assets/icons/status/SolarBattey3'
import SolarBattey4 from 'assets/icons/status/SolarBattey4'
import SolarBattery5 from 'assets/icons/status/SolarBattery5'
import { useTranslation } from 'react-i18next'
import CameraStatusBatteryTile from 'camera-module/camera/ui/camera-status-page/CameraStatusBatteryTile'

const SpypointFirstBatteryTile = ({ value, label, type, activePowerSource, voltage }) => {
  const { t } = useTranslation()
  let batteryValue = activePowerSource ? `${value}% - ${t('camera.in_use')}` : `${value}%`
  if (voltage === 0) batteryValue = t('camera.empty_tray')
  let Battery = Battery5
  if (value === 0) Battery = Battery0
  if (value > 0 && value <= 20) Battery = Battery1
  if (value > 20 && value <= 40) Battery = Battery2
  if (value > 40 && value <= 60) Battery = Battery3
  if (value > 60 && value <= 80) Battery = Battery4
  if (value > 80 && value <= 100) Battery = Battery5
  if (value === 0 && type === 'SOLAR') Battery = SolarBattey0
  if (value > 0 && value <= 20 && type === 'SOLAR') Battery = SolarBattey1
  if (value > 20 && value <= 40 && type === 'SOLAR') Battery = SolarBattey2
  if (value > 40 && value <= 60 && type === 'SOLAR') Battery = SolarBattey3
  if (value > 60 && value <= 80 && type === 'SOLAR') Battery = SolarBattey4
  if (value > 80 && value <= 100 && type === 'SOLAR') Battery = SolarBattery5
  if (voltage === 0) Battery = Battery0NA
  if (voltage === 0 && type === 'SOLAR') Battery = SolarBatteyNA

  return (
    <CameraStatusBatteryTile label={label} icon={<Battery />} value={value} batteryValue={batteryValue} useSVGIcon />
  )
}

export default SpypointFirstBatteryTile
