import moment from 'moment'
const formatFormData = (formData) => {
  const { name, email, cameraIds, value, historyControl, selectedGuestPhotoHistoryLimit } = formData

  const cameras = cameraIds.map(cameraId => ({
    cameraId,
    permissions: ['photo:read', 'geolocation:read'],
  }))
  const historyControlDate = () => {
    if (historyControl === 0) {
      const aYearFromNow = new Date()
      aYearFromNow.setFullYear(aYearFromNow.getFullYear() - 1)
      const isoString = new Date(aYearFromNow.setUTCHours(0, 0, 0, 0))
      return isoString.toISOString()
    }
    if (historyControl === 1) {
      const Now = new Date()
      const date = new Date(value)
      if (value) { return moment.utc(moment(date).format('DD/MM/YYYY'), 'DD/MM/YYYY').utcOffset(0).hours(0).minutes(0).seconds(0).milliseconds(0).format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z' } else if (selectedGuestPhotoHistoryLimit) { return selectedGuestPhotoHistoryLimit } else return (new Date(Now.setUTCHours(0, 0, 0, 0))).toISOString()
    }
    if (historyControl === 2) {
      const Now = new Date()
      return (new Date(Now.setUTCHours(0, 0, 0, 0))).toISOString()
    }
  }
  return {
    guestEmail: email,
    guestUsername: name,
    photoHistoryLimit: historyControlDate(),
    cameras,
  }
}

export const formatCameraError = {
  ALREADY_SHARED: 'This camera is already shared with this user',
  GUEST_USERNAME: 'User name already exists',
  get: function (key) {
    return this[key] ? this[key] : 'An error occurred'
  },
}
export default formatFormData
