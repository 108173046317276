import useCameraModelsAndVariants from '../../../../model/core/useCameraModelsAndVariants'
import CToggleSwitch from 'storybook-component-module/src/components/switch/CToggleSwitch'
import { Box } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import formatFormData from './GuestFormHelper'
import cameraSharingApi from '../../../api/cameraSharingApi'
import messageActions from 'shared-module/message/messageActions'
import { useState } from 'react'
import Spinner from 'shared-module/components/Spinner'
import { useTranslation } from 'react-i18next'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'

const CameraToggleItem = ({ camera, setMaxSharingCam, setFieldValue, cameraIds, disabled = false, editable = false, formValues, value, historyControl }) => {
  const model = useCameraModelsAndVariants(camera.status.model)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const removeGuestDialogActions = useConfirmationDialogActions()
  const { t } = useTranslation()

  const removeGuest = () => {
    const updatedCameraIds = cameraIds?.filter(id => id !== camera.id)
    setFieldValue('cameraIds', updatedCameraIds)
    setIsLoading(true)
    if (updatedCameraIds.length < 10) {
      setMaxSharingCam(false)
    }
    cameraSharingApi.deleteAccess({ cameraIds: [camera.id], guestEmail: formValues.email }).then(() => dispatch(messageActions.showSuccess(t('camera_sharing.guest_update')))).finally(() => setIsLoading(false))
  }

  const handleToggleChange = () => {
    let updatedCameraIds
    if (cameraIds?.includes(camera.id)) {
      updatedCameraIds = cameraIds?.filter(id => id !== camera.id)
      setFieldValue('cameraIds', updatedCameraIds)
      setMaxSharingCam(false)
    } else {
      updatedCameraIds = [...cameraIds, camera.id]
      if (updatedCameraIds.length > 10) {
        setMaxSharingCam(true)
        return
      }
      setFieldValue('cameraIds', updatedCameraIds)
    }

    // Manually update formValues
    const updatedFormValues = {
      ...formValues,
      cameraIds: updatedCameraIds,
    }
  }
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" gap="16px" mb="16px">
      <Box
        item
        style={{
          display: 'flex',
          flex: '1 1 auto',
          marginRight: '16px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '100%',
        }}
      >
        <img
          alt={model?.name}
          src={model?.icon}
          style={{
            height: '26px',
            marginRight: '16px',
            position: 'relative',
            top: '4px',
          }}
        />
        <span style={{
          fontSize: '17px',
          lineHeight: '20px',
          color: '#D3CFCF',
          fontFamily: 'ibm-plex-sans-regular',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
        >{ camera?.config?.name }
        </span>
      </Box>
      <Box display="flex" alignItems="center">
        { isLoading && <Spinner spinnerMarginTop="0px" position="relative" /> } { /* Display a loading message while loading */ }
        <CToggleSwitch
          checked={cameraIds?.includes(camera.id) || isLoading}
          color="primary"
          withLabel={false}
          disabled={disabled || isLoading}
          onChange={handleToggleChange}
        />
      </Box>
      <ConfirmationDialog
        isNewUi={false}
        open={removeGuestDialogActions.isOpen}
        title={t('camera_sharing.confirmation_remove_camera.title')}
        text={t('camera_sharing.confirmation_remove_camera.message')}
        onConfirm={() => {
          removeGuest()
          removeGuestDialogActions.close()
        }}
        onCancel={removeGuestDialogActions.close}
        confirmText={t('camera_sharing.confirmation_remove_camera.cta_button_send_invite')}
        cancelText={t('camera_sharing.confirmation_remove_camera.cta_button_cancel')}
      />
    </Box>
  )
}

export default CameraToggleItem
