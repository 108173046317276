import useUser from 'user-module/user/core/useUser'
import eCommerceActions from 'ecommerce-module/core/eCommerce.actions'
import Grid from '@material-ui/core/Grid'
import CTypography from 'storybook-component-module/src/components/typographies/CTypography'
import { Link } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    cursor: 'pointer',
  },
  text: {
    fontSize: '0.75rem',
  },
}))
const RedirectToSubscriptionManagement = ({ md = 10, spacing = 1 }) => {
  const user = useUser()
  const classes = useStyles()
  const { t } = useTranslation()
  const sentToSubscription = () => {
    eCommerceActions.goToSubscription(user)
  }
  return (
    <Grid container item sm={12} md={md} spacing={spacing} className={classes.container}>
      <Grid item>
        <CTypography variant="body2" className={classes.text}>{ t('app:club_membership.redirect_to_ecommerce.text_one') } <Link className={classes.link} onClick={sentToSubscription}>{ t('app:club_membership.redirect_to_ecommerce.Subscriptions') }</Link>{ t('app:club_membership.redirect_to_ecommerce.text_two') }</CTypography>
      </Grid>
    </Grid>
  )
}

export default RedirectToSubscriptionManagement
