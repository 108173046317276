import useQueryParams from 'shared-module/url/useQueryParams'
import eCommerceActions from 'ecommerce-module/core/eCommerce.actions'
import { useDispatch } from 'react-redux'

const useDirectPurchaseLogic = () => {
  const queryParams = useQueryParams()
  const dispatch = useDispatch()

  const addInsidersClubToBasket = (product) => {
    const newCartItem = product => [{
      productId: product?.id,
      productName: product?.name,
      quantity: product?.stepQuantity,
      price: product?.price,
    }]
    const storedBasketId = localStorage.getItem('basketId')
    if (!storedBasketId || storedBasketId === 'undefined') {
      eCommerceActions.createBaskets().then(newBasket => eCommerceActions.addItemsToBasket(newBasket.basketId, product))
    }
    dispatch(eCommerceActions.addItemsToBasket(storedBasketId, newCartItem(product)))
  }

  const addAddon = (product, basket) => {
    const productBasket = basket && basket.productItems?.filter((item) => {
      return item.productId === product[0].id
    })
    const isAddonInCart = productBasket && productBasket.length !== 0

    const newCartItem = product => [{
      itemId: product?.id,
      productId: product?.id,
      productName: product?.name,
      quantity: product?.stepQuantity,
      price: product?.price,
    }]
    const updateCartItem = productBasket => {
      return [{
        itemId: productBasket?.itemId,
        productId: productBasket?.productId,
        productName: productBasket?.itemText,
        quantity: productBasket?.quantity + 1,
        price: productBasket?.price,
      }]
    }
    const storedBasketId = localStorage.getItem('basketId')

    if (!storedBasketId || storedBasketId === 'undefined') {
      eCommerceActions.createBaskets().then(newBasket => eCommerceActions.addItemsToBasket(basket.basketId, newCartItem(product[0])))
    }
    if (isAddonInCart === true) {
      dispatch(eCommerceActions.updateBasketItems(storedBasketId, updateCartItem(productBasket[0])))
    } else {
      dispatch(eCommerceActions.addItemsToBasket(storedBasketId, newCartItem(product[0])))
    }
  }

  const directPurchaseEcommerce = (product, basket) => {
    if (queryParams.become_member === true) {
      addInsidersClubToBasket(product)
    }
    if (queryParams.photo_hd === true) {
      addAddon(product, basket)
    }
    if (queryParams.video_hd === true) {
      addAddon(product, basket)
    }
  }

  return { directPurchaseEcommerce }
}

export default useDirectPurchaseLogic
