import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import { useMediaQuery, useTheme } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

import useUser from 'user-module/user/core/useUser'
import CButton from 'storybook-component-module/src/components/buttons/CButton'
import CTitle from 'storybook-component-module/src/components/typographies/CTitle'
import { useCart } from 'spypoint/src/checkout-module/order/core/cart.hooks'
import { INSIDERS_CLUB_MEMBERSHIP } from 'spypoint/src/checkout-module/order/core/productTypes'
import { useLDFlags } from 'spypoint/src/launchDarkly-module/launchDarklyFlagsContext'
import eCommerceActions from 'ecommerce-module/core/eCommerce.actions'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import commerceSelector from 'ecommerce-module/core/eCommerce.selectors'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '0.75rem',
    [theme.breakpoints.only('sm')]: {
      marginLeft: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      paddingTop: '0.25rem',
      alignItems: 'center',
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
  },
  text: {
    fontSize: '1.313rem',
    lineHeight: '1.8rem',
    [theme.breakpoints.only('sm')]: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.125rem',
      textAlign: 'center',
    },
  },
}))

const JoinClubMembershipSection = ({ product }) => {
  const user = useUser()
  const theme = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()
  const { flags } = useLDFlags()
  const { navigateToCart, addToCart } = useCart()
  const smBreakpoints = useMediaQuery(theme.breakpoints.only('sm'))
  const eCommerceEnabled = flags && flags['e-commerce']
  const insiderClubProduct = product && product[0]
  const history = useHistory()
  const dispatch = useDispatch()
  const { basketId, productItems } = useSelector(commerceSelector.getProductItemsSelector)

  useEffect(() => {
    if (eCommerceEnabled) {
      dispatch(eCommerceActions.fetchProductItems())
    }
  }, [eCommerceEnabled, dispatch])

  const isItemInCart = productItems?.some((item) => {
    return item?.productId === commerceSelector.salesForceInsidersClub
  })

  const newCartItem = insiderClubProduct => [{
    productId: insiderClubProduct?.id,
    productName: insiderClubProduct?.name,
    quantity: insiderClubProduct?.stepQuantity,
    price: insiderClubProduct?.price,
  }]

  const addInsidersClubToBasket = () => {
    const storedBasketId = localStorage.getItem('basketId')
    if (!storedBasketId || storedBasketId === 'undefined') {
      eCommerceActions.createBaskets().then(newBasket => eCommerceActions.addItemsToBasket(newBasket.basketId, insiderClubProduct))
    }
    !isItemInCart && (
      dispatch(eCommerceActions.addItemsToBasket(storedBasketId, newCartItem(insiderClubProduct)))
    )
    navigateToCart(history)
  }

  const goToCart = () => {
    addToCart({ productId: INSIDERS_CLUB_MEMBERSHIP, paymentFrequency: 'annual' })
    navigateToCart()
  }

  // TODO: Remove dead code to access old subscription page

  const onClickToAddToCart = () => user.cartEnabled && goToCart()
  return (
    <Grid container item xs={12} md={9} spacing={smBreakpoints ? 1 : 3} className={classes.container}>
      <Grid item>
        <CTitle className={classes.text}>{ t('app:insiders_club.header.joinClubText') }</CTitle>
      </Grid>

      <Grid item>
        <CButton variety="primary" color="primary" onClick={eCommerceEnabled ? () => addInsidersClubToBasket(insiderClubProduct) : onClickToAddToCart}>
          { t('app:insiders_club.header.joinClubButton') }
        </CButton>
      </Grid>
    </Grid>
  )
}

export default JoinClubMembershipSection
