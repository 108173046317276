import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CartDivider from 'spypoint/src/checkout-module/order/ui/cart-lines/CartDivider'
import Amount from 'shared-module/components/amount/Amount'
import useUser from 'user-module/user/core/useUser'

const useStyles = makeStyles((theme) => ({
  savings: {
    fontSize: '1.625rem',
    fontFamily: 'oswald-normal',
    fontWeight: '500',
    textAlign: 'center',
    color: theme.palette.primary.main,
    flex: 'none',
    order: 10,
    flexGrow: 0,
  },
}))
const CartSavingsLineEcommerce = ({ savings }) => {
  const classes = useStyles()
  const user = useUser()
  const { t } = useTranslation()

  return (
    <Grid item>
      <CartDivider />
      <Typography className={classes.savings}>
        <span> { t('app:checkout.insiders_club_savings') } </span>
        { ' ' }
        <Amount value={savings} currencyCode={user.currencyCode} />
      </Typography>
    </Grid>
  )
}

export default CartSavingsLineEcommerce
