import userPaymentInformationApi from 'spypoint/src/user-module/payment-information/api/userPaymentInformationApi'

const type = 'SET_USER_PAYMENT_INFORMATION'

const set = info => ({ type, info })
const get = userId => dispatch => userPaymentInformationApi.get(userId).then(info => dispatch(set(info)))
const reducer = (state = null, action) => action.type === type ? action.info : state

const userPaymentInformationState = { get: get, set: set, reducer: reducer }
export default userPaymentInformationState
