import LEVELS from 'notifications-module/core/notificationLevels'
import ACTION_TYPES from 'notifications-module/core/notificationActionTypes'
import ICON_TYPES from 'notifications-module/core/notificationIconTypes'

const buildNotification = (apiNotification) => ({
  title: apiNotification.title,
  content: apiNotification.content,
  category: apiNotification.category,
  templateName: apiNotification.templateName,
  timeSinceCreation: apiNotification.createdAt ? apiNotification.createdAt : apiNotification.target.createdAt,
  read: apiNotification.read,
  level: buildLevel(apiNotification.level),
  Icon: buildIcon(apiNotification.icon, apiNotification.level),
  action: buildAction(apiNotification.action),
  targetKind: apiNotification.target.kind,
  targetId: apiNotification.target.id,
  id: apiNotification._id,
  isSticky: buildStickyProperty(apiNotification.level),
})

const buildLevel = (level) => {
  if (level === 'critical') return LEVELS.DANGER
  else if (level === 'high') return LEVELS.HIGH
  else if (level === 'mid') return LEVELS.MID
  else if (level === 'low') return LEVELS.INFO
}

const buildIcon = (icon, level) => {
  if (level === 'high') return ICON_TYPES.HIGH
  else return ICON_TYPES.INFO
}

const buildAction = (action) => {
  if (action === 'subscribePlan') return ACTION_TYPES.SUBSCRIBE_PLAN
  else if (action === 'upgradePlan') return ACTION_TYPES.UPGRADE_PLAN
  else if (action === 'hspa3GSunsetForm') return undefined
}

const buildStickyProperty = (level) => {
  return level === 'critical'
}

export default buildNotification
