
const buildName = apiCameraPlans => apiCameraPlans.c_dataPlanName
const buildIsActive = apiCameraPlans => apiCameraPlans.c_isActive
const buildIsUpgradable = apiCameraPlans => apiCameraPlans.c_isUpgradable
const buildPhotoCount = apiCameraPlans => apiCameraPlans.c_photoCountPerMonth
const buildDiscountPrice = apiCameraPlans => apiCameraPlans.productPromotions?.find(promo => promo.promotionId === 'InsidersClubDigitalDiscount')?.promotionalPrice || 0

const buildPlanPeriod = apiCameraPlans => apiCameraPlans.name.includes('Annual') ? 'annual' : 'month_by_month'
const buildECommercePlans = apiCameraPlans => {
  const builtCameraPlans = {}

  builtCameraPlans.id = apiCameraPlans.id
  builtCameraPlans.name = buildName(apiCameraPlans)
  builtCameraPlans.period = buildPlanPeriod(apiCameraPlans)
  builtCameraPlans.isUpgradable = buildIsUpgradable(apiCameraPlans)
  builtCameraPlans.isActive = buildIsActive(apiCameraPlans)
  builtCameraPlans.photoCountPerMonth = buildPhotoCount(apiCameraPlans)
  builtCameraPlans.price = apiCameraPlans.price
  builtCameraPlans.priceWithInsidersClub = buildDiscountPrice(apiCameraPlans)
  builtCameraPlans.stepQuantity = apiCameraPlans.stepQuantity
  builtCameraPlans.inventory = apiCameraPlans.inventory
  builtCameraPlans.primaryCategoryId = apiCameraPlans.primaryCategoryId

  return builtCameraPlans
}

const buildECommerceAddons = apiAddons => {
  const builtAddons = {}

  builtAddons.id = apiAddons.id
  builtAddons.name = buildAddonName(apiAddons)
  builtAddons.price = apiAddons.price
  builtAddons.stepQuantity = apiAddons.stepQuantity
  builtAddons.primaryCategoryId = apiAddons.primaryCategoryId
  builtAddons.priceWithInsidersClub = buildAddonDiscountPrice(apiAddons)

  return builtAddons
}

const buildAddonName = apiAddons => apiAddons.name
const buildAddonDiscountPrice = apiAddons => apiAddons.productPromotions?.find(promo => promo.promotionId === 'InsidersClubDigitalDiscount')?.promotionalPrice || 0

const buildBaskets = apiBasket => {
  const builtBasket = {}

  builtBasket.id = apiBasket.id
  builtBasket.name = buildName(apiBasket)
  builtBasket.totalPrice = apiBasket.totalPrice

  builtBasket.items = apiBasket.items.map(item => ({
    id: item.id,
    name: buildName(item),
    quantity: item.quantity,
    price: item.price,
  }))

  return builtBasket
}

const eCommerceBuilder = { buildECommercePlans, buildECommerceAddons, buildBaskets }
export default eCommerceBuilder
