import { Component } from 'react'
import { withTranslation } from 'react-i18next'

import Meter from 'checkout-module/checkout/ui/transmission-plans-page/Meter'
import { apiCameraPlans } from 'camera-module/camera/core/transmission-plan/core/cameraPlans.types'

import withStyles from '@material-ui/core/styles/withStyles'
import { compose } from 'redux'

const styles = (theme) => ({
  subscriptionGraphStyle: {
    [theme.breakpoints.down('xs')]: {
      width: '250px',
    },
    width: '350px',
    margin: '0 auto',
  },
})

class SubscriptionGraphs extends Component {
  /**
   * Rendering of the component.
   */
  render () {
    const { t, subscription, classes } = this.props
    let photoFill = 0
    let photoLimit = t('billing.unlimited_photos')
    if (subscription.plan.photoCountPerMonth) {
      photoLimit = subscription.plan.photoCountPerMonth
      photoFill = (subscription.photoCount / subscription.plan.photoCountPerMonth) * 100
    }

    const strongTitle = () => {
      const strongTitleTranslation = subscription.photoCount !== 1 ? t('billing.photos') : t('billing.photo')
      return `${subscription.photoCount || 0} ${strongTitleTranslation}`
    }

    const calculateDaysLeft = () => {
      const endDate = new Date(subscription.monthEndBillingCycle)
      const timestamp = endDate - Date.now()
      return Math.floor((timestamp / 1000 / 60 / 60 / 24) + 1)
    }

    const appendTitle = () => {
      const daysLeft = calculateDaysLeft()

      return daysLeft !== 1
        ? `${daysLeft} ${t('billing.days_left')}`
        : `${daysLeft} ${t('billing.day_left')}`
    }

    return (
      <div className={classes.subscriptionGraphStyle}>
        { subscription.plan.name !== apiCameraPlans.empty && (
          <Meter
            strongTitle={strongTitle()}
            title={t('billing.this_month')}
            appendStrongTitle={appendTitle()}
            width="100%"
            fill={photoFill}
            anchor="left"
            startLabel="0"
            endLabel={photoLimit}
          />
        ) }
      </div>
    )
  }
}

export default compose(withStyles(styles), withTranslation())(SubscriptionGraphs)
