import HdPackageIcon from 'assets/icons/navigation/HdPackageIcon'
import MenuItem from 'shared-module/navigation/menu/MenuItem'
import { useCart } from 'spypoint/src/checkout-module/order/core/cart.hooks'
import { VIDEOHD } from 'spypoint/src/checkout-module/order/core/productTypes'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import { useLDFlags } from 'spypoint/src/launchDarkly-module/launchDarklyFlagsContext'
import switches from '../../../config/switches'
import appConfig from '../../../config/appConfig'
import eCommerceActions from 'ecommerce-module/core/eCommerce.actions'
import commerceSelector from 'ecommerce-module/core/eCommerce.selectors'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const HdVideoPackageMenuItem = ({ onClick, addons }) => {
  const camera = useSelectedCamera()
  const { flags } = useLDFlags()
  const dispatch = useDispatch()
  const { addToCart, navigateToCart, updateQuantity, hdVideosItemInCart } = useCart()
  const history = useHistory()

  const flexFeatureEnabled = appConfig.flex.switch === switches.on
  const eCommerceEnabled = flags && flags['e-commerce']
  const { productItems } = useSelector(commerceSelector.getProductItemsSelector)

  const isAddonInCart = productItems?.some((item) => {
    return item?.productId === commerceSelector.salesForceHdVideoPackageId
  })

  const currentAddon = productItems?.filter(item => item?.productId === commerceSelector.salesForceHdVideoPackageId)

  const newCartItem = product => [{
    itemId: product?.itemId,
    productId: product?.id,
    productName: product?.name,
    quantity: product?.stepQuantity,
    price: product?.price,
  }]
  const updateCartItem = product => {
    return [{
      itemId: product?.itemId,
      productId: product?.productId,
      productName: product?.itemText,
      quantity: product?.quantity + 1,
      price: product?.price,
    }]
  }

  const addExtraItem = (product) => {
    const storedBasketId = localStorage.getItem('basketId')
    if (!storedBasketId || storedBasketId === 'undefined') {
      eCommerceActions.createBaskets().then(newBasket => eCommerceActions.addItemsToBasket(newBasket.basketId, newCartItem(product[0])))
    }
    if (isAddonInCart) {
      dispatch(eCommerceActions.updateBasketItems(storedBasketId, updateCartItem(currentAddon[0])))
    } else {
      dispatch(eCommerceActions.addItemsToBasket(storedBasketId, newCartItem(product[0])))
    }
    navigateToCart(history)
  }

  const showCart = () => {
    const product = addons?.plans.plans.filter(item => item.id === commerceSelector.salesForceHdVideoPackageId)
    if (eCommerceEnabled) {
      addExtraItem(product)
    }
    if (hdVideosItemInCart) {
      updateQuantity({
        itemId: hdVideosItemInCart.id,
        quantity: hdVideosItemInCart.quantity + 1,
      })
    } else {
      addToCart({ productId: VIDEOHD })
    }
    onClick()
    navigateToCart()
  }

  const targetedModelsByName = { FLEX: true, 'FLEX-S': true, 'FLEX G-36': true, 'FLEX E-36': true }

  return (camera?.status?.capability?.video || targetedModelsByName[camera?.status?.model]) && flexFeatureEnabled
    ? (
      <MenuItem Icon={HdPackageIcon} text="menu.hd_video_package" onClick={showCart} path="/cart" />
      )
    : null
}

export default HdVideoPackageMenuItem
