import { useTranslation } from 'react-i18next'

import { Grid } from '@material-ui/core'

import useUser from 'user-module/user/core/useUser'
import LineTitle from 'spypoint/src/checkout-module/order/ui/cart-lines/LineTitle'
import VoskerSecondaryButton from 'spypoint/src/components/buttons/VoskerSecondaryButton'
import SecondaryButton from 'shared-module/new-components/buttons/SecondaryButton'

const BillingAddressDisplay = ({ isNewUi = false, modifyBillingAddress }) => {
  const user = useUser()
  const { t } = useTranslation()

  const Button = isNewUi ? VoskerSecondaryButton : SecondaryButton

  return (
    <>
      <Grid item xs={8}>
        <LineTitle full>
          { user.firstName } { user.lastName }
        </LineTitle>
        <LineTitle full>{ user.address }</LineTitle>
        <LineTitle full>{ user.apartment ? `Apt. ${user.apartment}` : '' }</LineTitle>
        <LineTitle full>
          { user.city }, { user.country } { user.postalCode }
        </LineTitle>
      </Grid>
      <Grid item xs={4}>
        <Button fullWidth color="primary" onClick={modifyBillingAddress}>
          { t('app:order_page.billing.modify') }
        </Button>
      </Grid>
    </>
  )
}

export default BillingAddressDisplay
