export const eCommerceTokenID = 'salesForceToken'
export const eCommerceRefreshTokenID = 'salesForceRefreshToken'

const salesForcePlansIds = 'D06150,D06151'
const salesForceHdPackageId = 'D06150'
const salesForceHdVideoPackageId = 'D06151'
const salesForceAddonsIds = 'D06251'
const salesForceInsidersClub = 'D06100'
export const salesForceAddonsPlusQuantityEnabled = ['D06150', 'D06151']
export const getProductItemsSelector = state => ({
  basketId: state.ecommerceReducer?.productItems?.basketId,
  productItems: state.ecommerceReducer?.productItems?.productItems,
  productSubTotal: state.ecommerceReducer?.productItems?.productSubTotal,
  productTotal: state.ecommerceReducer?.productItems?.productTotal,
})
export const getBasketSelector = state => ({
  basket: state.ecommerceReducer?.basket?.basket,
})

const commerceSelector = { salesForcePlansIds, salesForceHdPackageId, salesForceHdVideoPackageId, salesForceAddonsIds, salesForceInsidersClub, salesForceAddonsPlusQuantityEnabled, getProductItemsSelector, getBasketSelector }
export default commerceSelector
