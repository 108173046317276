import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import useDetectionZone from 'detection-zone-module/core/detectionZone.hooks'
import CButton from 'storybook-component-module/src/components/buttons/CButton'
import VoskerSecondaryButton from 'spypoint/src/components/buttons/VoskerSecondaryButton'
import Grid from '@material-ui/core/Grid'

const DetectionField = ({ isNewUi, dialogActions }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const camera = useSelectedCamera()
  const { isDefaultZone } = useDetectionZone()
  const { allPhotosLoaded, photos } = useSelector(state => state.galleryPhoto)

  const detectionZoneAction = () => { if (photos.length === 0) { dialogActions.open() } else history.push(`/camera/${camera.id}/detection-zone`) }
  const CreateButton = isNewUi ? VoskerSecondaryButton : CButton

  return (
    <Grid display={isNewUi ? 'flex' : null} flexdirection="row" justifyContent="flex-end" container spacing={1}>
      <Grid items xs={6} sm={3}><CreateButton color="primary" variety="secondary" onClick={detectionZoneAction}>
        { isDefaultZone ? t('camera.settings.buttons.create') : t('camera.settings.buttons.edit') }
      </CreateButton>
      </Grid>
    </Grid>
  )
}

export default DetectionField
