import { combineReducers } from 'redux'

const initialState = {
  subscriptions: [],
}

const subscriptions = (state = initialState.subscriptions, action) => {
  switch (action.type) {
    case 'SET_SUBSCRIPTIONS':
      return action.subscription?.data || []
    default:
      return state
  }
}

export default combineReducers({
  subscriptions,
})
