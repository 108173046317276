import produce from 'immer'

const defaultState = { notifications: [] }
const hasNotifications = state => state.notifications && state.notifications.length > 0

const set = (state, action) => ({ notifications: action.notifications })

const sort = (state) => produce(state, newState => {
  const items = newState.notifications
  items.sort((a, b) => b.timestamp - a.timestamp)
})

const isRead = (state, action) => produce(state, newState => {
  const notification = newState.notifications.find(n => n.id === action.id)
  if (notification) notification.read = true
})

const notificationState = { defaultState, hasNotifications, set, sort, isRead }
export default notificationState
