import VoskerPrimaryButton from 'spypoint/src/components/buttons/VoskerPrimaryButton'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import messageActions from 'shared-module/message/messageActions'
import { makeStyles } from '@material-ui/core/styles'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import { TAKE_PHOTO, TAKE_VIDEO } from 'camera-module/camera/core/cameraSettingsButtonInputTypes'
import cameraCommandsApi from 'camera-module/camera/api/cameraCommandsApi'

import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  button: {
    height: '2.5rem',
    minHeight: '2.5rem',
  },
}))

const GalleryOnDemand = ({
  isNewUi = false,
  camera,
  requestPending,
  setRequestPending,
  showOndemand,
  setShowOnDemand,
  noAlerts,
  setNoAlerts,
  cameraNotAvailable,
  setCameraNotAvailable,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const dialogActions = useConfirmationDialogActions()
  const dispatch = useDispatch()

  // constants
  const PHOTO_DEDUCTION_FOR_VIDEO = 3
  const TIME_LIMIT_TO_SYNC = 48
  let callCount = 0

  const hasLimit = camera?.subscription?.plan?.photoCountPerMonth > 0
  const outOfVideos = hasLimit && camera?.subscription?.photoCount + PHOTO_DEDUCTION_FOR_VIDEO > camera?.subscription?.plan?.photoCountPerMonth
  const outOfPhotos = hasLimit && camera?.subscription?.photoCount >= camera?.subscription?.plan?.photoCountPerMonth

  const isOperationModeInstant = camera?.config?.operationMode === 'instant'

  const cameraLastSync = camera?.status?.lastUpdate
  const currentTime = new Date().valueOf()
  const timestamp = new Date(cameraLastSync).valueOf()
  const noCommunicationWithCamera = Math.round((currentTime - timestamp) / (36e5)) > TIME_LIMIT_TO_SYNC

  useEffect(() => {
    camera && getCommands()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camera])

  function resetRequest () {
    cameraCommandsApi
      .getCommandForm(camera.id)
      .then((commands) => {
        if (commands.filter((command) => command.name === TAKE_PHOTO && command.isSet === false || command.name === TAKE_VIDEO && command.isSet === false).length === 2) {
          setRequestPending(false)
        }
      })
  }

  function resetRequestAttempt () {
    if (callCount < 4) {
      resetRequest()
      callCount++
      setTimeout(resetRequestAttempt, 10000)
    }
  }

  const getCommands = () => {
    cameraCommandsApi
      .getCommandForm(camera.id)
      .then((command) => {
        setRequestPending(command.some((command) => command.name === TAKE_PHOTO && command.isSet === true || command.name === TAKE_VIDEO && command.isSet === true))
        setShowOnDemand(true)
      })
      .catch(() => dispatch(messageActions.showError('errors.catch_all')))
  }

  const sendCommand = (command, cancel) => {
    const requestBody = {
      command: command,
    }
    cameraCommandsApi
      .requestCommandWithBody(camera.id, requestBody)
      .then((res) => {
        setRequestPending(true)
        resetRequestAttempt()
      })
      .catch(() => dispatch(messageActions.showError('errors.catch_all')))
  }

  const openDialog = () => {
    dialogActions.open()
  }
  const closeDialog = () => {
    dialogActions.close()
  }

  const requestPhoto = () => {
    if (outOfPhotos) {
      dialogActions.close()
      setNoAlerts(true)
      dialogActions.open()
    } else if (noCommunicationWithCamera) {
      dialogActions.close()
      setCameraNotAvailable(true)
      dialogActions.open()
    } else {
      sendCommand(TAKE_PHOTO, false)
      dialogActions.close()
    }
  }

  const requestVideo = () => {
    if (outOfVideos) {
      dialogActions.close()
      setNoAlerts(true)
      dialogActions.open()
    } else if (noCommunicationWithCamera) {
      dialogActions.close()
      setCameraNotAvailable(true)
      dialogActions.open()
    } else {
      sendCommand(TAKE_VIDEO, false)
      dialogActions.close()
    }
  }

  const goToCameraSettings = () => {
    closeDialog()
    history.push(`/camera/${camera.id}/settings`)
  }

  const goToPlans = () => {
    closeDialog()
    history.push(`/camera/${camera.id}/plan`)
  }

  return (
    isNewUi && (
      <>
        { showOndemand && (
          <VoskerPrimaryButton
            size="large"
            id="activeMultiDelete"
            onClick={openDialog}
            className={classes.button}
            disabled={requestPending}
          >
            { requestPending ? t('on_demand.processing') : t('on_demand.onDemand') }
          </VoskerPrimaryButton>
        ) }
        {
          isOperationModeInstant && !noAlerts && !cameraNotAvailable && (
            <ConfirmationDialog
              isNewUi={isNewUi}
              open={dialogActions.isOpen}
              onClose={closeDialog}
              onConfirm={requestPhoto}
              onSecondConfirm={requestVideo}
              closeActionIcon
              title={t('on_demand.request.title')}
              text={t('on_demand.request.text')}
              confirmText={t('on_demand.request.photo')}
              confirmSecondText={t('on_demand.request.video')}
            />
          )
        }
        { !isOperationModeInstant && (
          <ConfirmationDialog
            isNewUi={isNewUi}
            open={dialogActions.isOpen}
            onClose={closeDialog}
            onConfirm={goToCameraSettings}
            onCancel={closeDialog}
            title={t('on_demand.instant.title')}
            text={t('on_demand.instant.text')}
            confirmText={t('on_demand.instant.go_to_settings')}
          />
        ) }
        { noAlerts && (
          <ConfirmationDialog
            isNewUi={isNewUi}
            open={dialogActions.isOpen}
            onClose={closeDialog}
            onCancel={closeDialog}
            onConfirm={goToPlans}
            title={t('on_demand.no_alerts.title')}
            text={t('on_demand.no_alerts.text')}
            confirmText={t('on_demand.no_alerts.upgrade_plan')}
          />
        ) }
        { cameraNotAvailable && (
          <ConfirmationDialog
            isNewUi={isNewUi}
            open={dialogActions.isOpen}
            onClose={closeDialog}
            onCancel={closeDialog}
            title={t('on_demand.camera_not_available.title')}
            text={t('on_demand.camera_not_available.text')}
            cancelText={t('on_demand.camera_not_available.confirm')}
          />
        ) }
      </>
    )
  )
}

export default GalleryOnDemand
