import { useFormikContext } from 'formik'
import { CircularProgress } from '@material-ui/core'

import { canSubmitForm, isFormLoading, isFormTouched } from '../util'
import VoskerPrimaryButton from 'spypoint/src/components/buttons/VoskerPrimaryButton'
import CButton from 'storybook-component-module/src/components/buttons/CButton'

const SubmitButton = ({
  children,
  disabled = false,
  isNewUi = false,
  ...buttonProps
}) => {
  const form = useFormikContext()
  const PrimaryButton = isNewUi ? VoskerPrimaryButton : CButton

  return (
    <PrimaryButton
      type="submit"
      disableElevation
      variant="contained"
      {...buttonProps}
      disabled={!isFormTouched(form) || !canSubmitForm(form) || disabled}
    >
      { children }
      { isFormLoading(form) && (<CircularProgress size={20} style={{ marginLeft: '.75rem' }} />) }
    </PrimaryButton>
  )
}

export default SubmitButton
