import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Arrow from 'assets/icons/NotificationArrow'
import TimeAgo from 'shared-module/components/TimeAgo'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useTheme } from '@material-ui/core'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'react-i18next'
import { useNotifications, useNotificationsActions } from 'notifications-module/core/notifications.hooks'
import LEVELS from 'notifications-module/core/notificationLevels'
import { title, content } from './helper'
import { useState } from 'react'

const useStyles = makeStyles(theme => ({
  notification: {
    borderBottom: `solid 1px ${theme.palette.background.paper}`,
    cursor: 'pointer',
    background: theme.palette.background.default,
  },
  unreadNotification: {
    background: '#1F1D1D',
    '&:hover': {
      background: '#313030',
    },
  },
  arrow: {
    width: 8.75,
    transition: 'transform 0.3s ease-in-out',
  },
  arrowUp: {
    transform: 'rotate(0deg)',
  },
  arrowDown: {
    transform: 'rotate(90deg)',
  },
  title: {
    maxWidth: '27ch',
    fontFamily: theme.typography.fontFamily,
    marginBottom: '12px',
    fontSize: '16px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  truncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: '700',
  },
  content: {
    maxWidth: '30ch',
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    marginBottom: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    webkitLineClamp: 3,
    whiteSpace: 'nowrap',
    'line-clamp': '3',
    '-webkit-box-orient': 'vertical',
  },
  unreadText: {
    fontFamily: theme.typography.fontWeightRegular,
    fontWeight: '700',
  },
  timestamp: {
    display: 'block',
    fontFamily: theme.typography.fontFamily,
    fontSize: '1em',
    color: theme.palette.text.secondary,
    paddingTop: 5,
  },
  warning: {
    borderLeftColor: theme.palette.primary.main,
  },
  danger: {
    borderLeftColor: theme.palette.primary.danger,
  },
  info: {
    borderLeftColor: 'transparent',
  },
  critical: {
    borderLeftColor: 'transparent',
  },
  criticalUnread: {
    borderLeftColor: theme.palette.primary.danger,
  },
  read: {
    backgroundColor: theme.palette.primary.danger,
    '&:hover': {
      background: 'transparent',
    },
  },
}))

const SpypointNotificationItem = ({ id, timeSinceCreation, level, read, Icon, onClose, templateName, targetId, cameraList, expandedNotificationId, setExpandedNotificationId }) => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const notifications = useNotifications()
  const notificationsActions = useNotificationsActions()
  const breakpoint = useMediaQuery(theme.breakpoints.down('xs'))
  const cameraName = cameraList?.find(camera => camera.id === targetId)?.config?.name ?? ''
  const onClick = () => {
    const notification = notifications.find(n => n.id === id)
    if (!notification.read) { notificationsActions.markAsRead(notification) }
    notificationsActions.handleNotificationAction(notification)
    onClose()
  }

  const handleArrowClick = (event) => {
    event.stopPropagation() // Prevent triggering onClick of parent
    setExpandedNotificationId(id !== expandedNotificationId ? id : null)
  }

  const isExpanded = id === expandedNotificationId
  const getLevelClass = () => {
    if (level === LEVELS.INFO) { return classes.info }
    if (level === LEVELS.MID) { return classes.warning }
    if (level === LEVELS.HIGH) { return classes.danger }
    if (level === LEVELS.DANGER) { return read ? classes.critical : classes.criticalUnread }
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      py={2}
      className={clsx(classes.notification,
        getLevelClass(), !read && classes.unreadNotification)}
      onClick={onClick}
    >
      <Box paddingLeft="24px" paddingRight="24px">
        <Icon style={{ width: '22px', height: '22px' }} />
      </Box>
      <Box flex={1} width={breakpoint ? 150 : 'max-content'}>
        <Typography className={clsx(classes.title, !read && classes.unreadText)}>{ t(title[templateName], { cameraName }) }</Typography>
        <Typography className={clsx(classes.content, !read && classes.truncate)}>{ t(content[templateName], { cameraName }) }</Typography>
        { timeSinceCreation && <Typography className={classes.timestamp}><TimeAgo timestamp={timeSinceCreation} /></Typography> }
      </Box>
      
    </Box>
  )
}

export default SpypointNotificationItem
