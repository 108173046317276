import { useTranslation } from 'react-i18next'
import Amount from 'shared-module/components/amount/Amount'
import DiscountLine from './DiscountLine'
import { Grid } from '@material-ui/core'

const EcommerceItemDiscountLine = ({ product, currency }) => {
  const { t } = useTranslation()
  const insidersLabel = 'app:checkout_process.cart.insiders_club_rebate'

  return (
    <Grid container justifyContent="space-between" spacing={3}>
      <DiscountLine label={
      t(insidersLabel, { discount: 20 })
    }
      >
        <Amount
          value={product?.priceAdjustments?.[0]?.price}
          currencyCode={currency}
        />
      </DiscountLine>
    </Grid>
  )
}

export default EcommerceItemDiscountLine
