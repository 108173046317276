import FormControl from '@material-ui/core/FormControl'

import { useFormData } from 'shared-module/new-components/form/FormDataContext'
import CButton from 'storybook-component-module/src/components/buttons/CButton'
import VoskerPrimaryButton from 'spypoint/src/components/buttons/VoskerPrimaryButton'

const FormButton = ({ isNewUi = false, children }) => {
  const form = useFormData()
  const SubmitButton = isNewUi ? VoskerPrimaryButton : CButton

  return (
    <FormControl margin="dense">
      <SubmitButton id="btn_login" color="primary" variety="primary" type="submit" disabled={form.formState.isSubmitting}>
        { children }
      </SubmitButton>
    </FormControl>
  )
}

export default FormButton
