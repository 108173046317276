import React from 'react'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CCheckbox from 'storybook-component-module/src/components/buttons/CCheckbox'
import VoskerCheckbox from 'spypoint/src/components/VoskerCheckbox'
import useLanguage from 'shared-module/components/language/useLanguage'
import { useTranslation } from 'react-i18next'
import Link from '@material-ui/core/Link'

const useStyles = makeStyles((theme) => ({
  label: {
    marginTop: 10,
  },
  link: {
    color: `${theme.palette.status?.info}`,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  unchecked: {
    fill: 'transparent !important',
  },
  box: {
    marginLeft: -10,
    marginBottom: 24,
  },
}))

const TermsCheckbox = ({ disabled, isNewUi, checked, setChecked }) => {
  const language = useLanguage()
  const { t } = useTranslation()
  const classes = useStyles()
  const CheckBox = isNewUi ? VoskerCheckbox : CCheckbox
  const disabledColor = isNewUi ? 'textSecondary' : 'secondary'
  const handleChange = (e) => {
    setChecked(e.target.checked)
  }
  const voskerTermsHref = language === 'en' ? 'https://www.vosker.com/app/terms-of-service/' : 'https://www.vosker.com/conditions-dutilisation'
  const voskerPolicyHref = language === 'en' ? 'https://www.vosker.com/app/privacy-policy/' : 'https://www.vosker.com/app/confidentialite/?lang=fr'
  const spypointTermsHref = language === 'en' ? 'https://www.spypoint.com/en/app/terms-use' : 'https://www.spypoint.com/fr/app/conditions-utilisation'
  const spypointPolicyHref = language === 'en' ? 'https://www.spypoint.com/en/app/privacy-policy' : 'https://www.spypoint.com/fr/app/confidentialite'
  const termsHref = isNewUi ? voskerTermsHref : spypointTermsHref
  const policyHref = isNewUi ? voskerPolicyHref : spypointPolicyHref
  // @ts-ignore
  return (
    <Box className={classes.box} display="flex" flexDirection="row" alignItems="flex-start">
      <CheckBox
        disabled={disabled}
        checked={checked}
        onChange={(e) => handleChange(e)}
        uncheckedClassName={classes.unchecked}
      />
      <Typography color={disabled ? disabledColor : 'textPrimary'}>
        <Typography className={classes.label}>
          { t('input.terms_conditions_1') }
          <Link href={termsHref} target="_blank" rel="noreferrer" className={classes.link}>
            { t('input.terms_conditions') }
          </Link>
          { t('input.terms_conditions_2') }
          <Link href={policyHref} target="_blank" rel="noreferrer" className={classes.link}>
            { t('input.policy') }
          </Link>
        </Typography>
      </Typography>
    </Box>
  )
}

export default TermsCheckbox
