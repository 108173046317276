import eCommerceApi from 'ecommerce-module/api/eCommerceApi'
import { eCommerceRefreshTokenID, eCommerceTokenID } from 'ecommerce-module/core/eCommerce.selectors'
import { SET_CAMERA_PLANS } from 'camera-module/camera/core/transmission-plan/core/cameraPlans.actions'

export const SET_PRODUCTS = 'SET_PRODUCTS'
export const SET_PRODUCT_ITEMS = 'SET_PRODUCT_ITEMS'
export const SET_BASKET = 'SET_BASKET'

const setProductItems = productItems => ({ type: SET_PRODUCT_ITEMS, productItems })
const setBasket = basket => ({ type: SET_BASKET, basket })

export const fetchProductItems = () => async dispatch => {
  try {
    const basketId = localStorage.getItem('basketId')
    const productSubTotal = 0
    const productTotal = 0
    let productItems = []
    let basket = []

    if (basketId && basketId !== 'undefined') {
      const basketDetails = await eCommerceApi.getBasket(basketId)
      if (basketDetails && basketDetails.basketId) {
        productItems = basketDetails.productItems
        basket = basketDetails
      } else {
        const newBasket = await eCommerceApi.createBasket()
        if (newBasket?.basketId) {
          localStorage.setItem('basketId', newBasket.basketId)
          productItems = newBasket.productItems
          basket = newBasket
        }
      }
    } else {
      const newBasket = await eCommerceApi.createBasket()
      if (newBasket?.basketId) {
        localStorage.setItem('basketId', newBasket.basketId)
        productItems = newBasket.productItems
        basket = newBasket
      }
    }

    dispatch(setProductItems({ basketId, productItems }))
    dispatch(setBasket({ basket: basket }))
  } catch (err) {
    console.error(err)
  }
}

const setProducts = products => ({ type: SET_PRODUCTS, products })
const setPlans = plans => ({ type: SET_CAMERA_PLANS, plans })
const authenticateUser = () => eCommerceApi
  .authenticate()
  .then(data => {
    localStorage.setItem(eCommerceRefreshTokenID, data.refresh_token)
  })
  .catch(error => error)

const fetchProducts = () => dispatch => eCommerceApi.fetchProducts()
  .then(plans => dispatch(setProducts({ plans: plans })))
  .catch(error => error)
const fetchPlans = () => dispatch => eCommerceApi.fetchPlans()
  .then(plans => dispatch(setPlans({ plans: plans })))
  .catch(error => error)
const createBaskets = () => eCommerceApi
  .createBasket()
  .then(data => data)
  .catch(error => error)

const addItemsToBasket = (basketId, item) => async dispatch => {
  try {
    const data = await eCommerceApi.addItemToBasket(basketId, item)
    dispatch(setProductItems({ basketId: data.basketId, productItems: data.productItems }))
    dispatch(setBasket({ basket: data }))
  } catch (error) {
    console.error(error)
  }
}

const updateBasketItems = (basketId, item) => async dispatch => {
  try {
    const data = await eCommerceApi.updateBasketItem(basketId, item)
    dispatch(setProductItems({ basketId: data.basketId, productItems: data.productItems }))
    dispatch(setBasket({ basket: data }))
  } catch (error) {
    console.error(error)
  }
}

const getBasket = (basketId) => eCommerceApi
  .getBasket(basketId)
  .then(data => data)
  .catch(error => error)

const deleteBasket = (storedBasketId) => eCommerceApi
  .deleteBasket(storedBasketId)
  .then(data => data)
  .catch(error => error)

export const deleteItemFromBasket = (storedBasketId, item) => async dispatch => {
  try {
    const data = await eCommerceApi.deleteItemFromBasket(storedBasketId, item)
    dispatch(setProductItems({ basketId: data.basketId, productItems: data.productItems }))
    dispatch(setBasket({ basket: data }))
  } catch (error) {
    console.error(error)
  }
}

const proceedToPayment = (user) => {
  const auth = localStorage.getItem(eCommerceTokenID)
  const redirectionURL = window.location.origin
  const countryCode = user.currency === 'USD' ? 'us' : 'ca'
  const language = user.language === 'en' || user.language === 'fr' ? user.language : 'en'

  const origin = window.location.hostname === 'localhost'
    ? 'dev.spypoint'
    : window.location.hostname.replace('webapp', '').replace('.com', '').substring(1)

  const sfccOrigin = `https://cc.${origin}.com/${countryCode}/${language}/checkout/external`
  const isLocalhost = window.location.hostname === 'localhost'
  const params = new URLSearchParams({
    ...(isLocalhost && { auth }),
    url: redirectionURL,
  })

  window.location.assign(`${sfccOrigin}?${params}`)
}
const goToSubscription = (user) => {
  const auth = localStorage.getItem(eCommerceTokenID)
  const redirectionURL = window.location.origin
  const countryCode = user.currency === 'USD' ? 'us' : 'ca'
  const language = user.language === 'en' || user.language === 'fr' ? user.language : 'en'

  const origin = window.location.hostname === 'localhost'
    ? 'dev.spypoint'
    : window.location.hostname.replace('webapp', '').replace('.com', '').substring(1)

  const sfccOrigin = `https://cc.${origin}.com/${countryCode}/${language}/account/external`
  const isLocalhost = window.location.hostname === 'localhost'
  const params = new URLSearchParams({
    ...(isLocalhost && { auth }),
    url: redirectionURL,
  })

  window.location.assign(`${sfccOrigin}?${params}`)
}

const eCommerceActions = { authenticateUser, fetchProducts, setPlans, fetchPlans, setProducts, createBaskets, addItemsToBasket, getBasket, deleteBasket, deleteItemFromBasket, proceedToPayment, goToSubscription, fetchProductItems, updateBasketItems }
export default eCommerceActions
