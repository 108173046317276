import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const WarningIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon viewBox="0 0 18 18" {...props} data-testid="WarningIcon">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.62119 1.05051C9.50355 0.815238 9.26314 0.666626 9.00008 0.666626C8.73703 0.666626 8.49661 0.815238 8.37897 1.05051L0.740063 16.3283C0.632428 16.5436 0.643931 16.7992 0.770463 17.0039C0.896996 17.2086 1.12051 17.3333 1.36119 17.3333H16.639C16.8797 17.3333 17.1031 17.2086 17.2297 17.0039C17.3563 16.7992 17.3677 16.5436 17.2601 16.3283L9.62119 1.05051ZM9.69452 6.91663H8.30564V11.7777H9.69452V6.91663ZM8.30564 13.1666H9.69452V14.5555H8.30564V13.1666Z" fill="#F9B643" />
    </SvgIcon>
  )
}

export default WarningIcon
