import { useTheme } from '@material-ui/core'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import InformationBarElement from 'photo-module/photos/ui/photos-gallery/information-bar/InformationBarElement'
import PhotoGalleryIcon from 'assets/icons/navigation/PhotoGalleryIcon'

const PhotoCountElement = ({ style, className }) => {
  const theme = useTheme()
  const camera = useSelectedCamera()
  const formatPhotoCount = subscription => `${subscription.photoCount}/${subscription.plan.photoCountPerMonth} photos`

  return camera && camera.subscription.plan.id !== 'Premium'
    ? <InformationBarElement text={formatPhotoCount(camera.subscription)} Icon={PhotoGalleryIcon} style={style} customHover className={className} dataTestId="NbPhotosInGallery" />
    : <div style={{ borderLeft: `4px solid ${theme.palette.background.default}` }} />
}

export default PhotoCountElement
