import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import FormControl from '@material-ui/core/FormControl'
import { useFormData } from 'shared-module/new-components/form/FormDataContext'
import useUserActions from 'user-module/user/core/useUserActions'
import messageActions from 'shared-module/message/messageActions'
import CButton from 'storybook-component-module/src/components/buttons/CButton'
import VoskerPrimaryButton from 'spypoint/src/components/buttons/VoskerPrimaryButton'
import useUser from '../../../core/useUser'

const PreferencesButton = ({ isNewUi = false, checked, onClose, checkedEmail, setIsOpenNewsLetterConfirmationDialog, optInB = false, optInLastB, optInSubscribe }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useUser()
  const userActions = useUserActions()

  const SubmitButton = isNewUi ? VoskerPrimaryButton : CButton

  const buildApiUser = () => ({
    marketingEmail: true,
  })
  const register = async () => {
    const userData = buildApiUser()
    userActions.update({ id: user.id, ...userData })
      .then(() => {
        onClose()
      })
      .catch(() => {
        dispatch(messageActions.showError())
      })
  }

  return (
    <FormControl style={{ width: '100%' }}>
      <SubmitButton onClick={() => register()} color="primary" variety="primary">
        { t('preferences.PrimaryButton') }
      </SubmitButton>
    </FormControl>
  )
}

export default PreferencesButton
