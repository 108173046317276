import restapi from 'shared-module/api/restapi'
import handleError from 'shared-module/api/apiError'
import { NOT_FOUND } from 'shared-module/api/httpStatusCode'
import buildPaymentInformation from 'spypoint/src/user-module/payment-information/api/paymentInformationBuilder'

const post = (userId, token) => restapi
  .post(`/api/v3/user/${userId}/payment-information`, { stripeToken: token })
  .then((response) => buildPaymentInformation(response.data))
  .catch(handleError)

const get = (userId) => restapi
  .get(`/api/v3/user/${userId}/payment-information`)
  .then((response) => buildPaymentInformation(response.data))
  .catch(error => error.response?.status === NOT_FOUND ? { noCard: true } : handleError(error))

const getStripePublicKey = () => restapi
  .get('/api/v3/paymentservice/publickey')
  .then(publicKey => publicKey.data.key)
  .catch(handleError)

const userPaymentInformationApi = { post: post, get: get, getStripePublicKey: getStripePublicKey }
export default userPaymentInformationApi
