import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import usePhotoActions from 'photo-module/photo/core/usePhotoActions'
import SelectPhoto from 'photo-module/photos/ui/photos-gallery/SelectPhoto'
import usePhotosDelete from 'photo-module/photos/ui/photos-gallery/usePhotosDelete'
import { useEffect, useState } from 'react'
import LazyLoad from 'react-lazyload'
import { useHistory } from 'react-router-dom'
import IconNoPhoto from 'shared-module/components/IconNoPhoto'
import VoskerNoImgIcon from 'camera-module/cameras/ui/cameras-page/icons/VoskerNoImgIcon'
import videoState from 'video-module/video-clip/core/videoState'
import Box from '@material-ui/core/Box'
import Observer from 'react-intersection-observer'
import { useSelector } from 'react-redux'
import VoskerLoadImageIcon from 'spypoint/src/photos-module/photos/ui/icons/VoskerLoadImageIcon'

const defaultImageSize = '167px'
const mobileImageSize = '100%'

const useStyles = makeStyles(theme => ({
  photoWrapper: {
    width: defaultImageSize,
    height: 83,
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      width: mobileImageSize,
      height: 'auto',
      overflow: 'visible',
    },
  },
  photo: {
    margin: 5,
    [theme.breakpoints.down('xs')]: {
      width: '46%',
    },
  },
  tileWrapper: {
    position: 'relative',
    cursor: 'pointer',
    width: defaultImageSize,
    height: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: mobileImageSize,
    },
  },
  photoNotFound: {
    position: 'relative',
    cursor: 'not-allowed',
    width: defaultImageSize,
    height: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: mobileImageSize,
    },
  },
  selectCheckbox: {
    position: 'absolute',
    left: 16,
    top: 4,
  },

  gradient: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    background: 'linear-gradient(150deg, rgba(0,0,0,0) 50%, rgba(0,0,0,0.75) 100%)',
    zIndex: 2,
  },
  loadedImageStyle: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: mobileImageSize,
    },
    height: '5.625rem',
    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      transform: 'none',
    },
  },
  defaultImageStyle: {
    width: 0,
    height: 0,
  },
  voskerNoPhoto: {
    height: '5.625rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.basic?.black,
  },
  noImgIcon: {
    width: '2.25rem',
    height: '2.25rem',
  },
  photoWrapperNoImg: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'inherit',
    height: 90,
    margin: '0.1rem 0',
    borderRadius: '0.375rem',
    background: theme.palette.background?.empty,
  },
  roundedEdges: {
    borderRadius: '0.375rem',
  },
}))

const PhotoTile = ({ photo, PhotoOverlay, isNewUi = false, setIsIntersecting }) => {
  const classes = useStyles()
  const history = useHistory()
  const photoAction = usePhotoActions()
  const { multiSelectEnabled, photosToDeleteActions, photoIdsToDelete } = usePhotosDelete()
  const { fetching: isPhotosFetching, photos } = useSelector(state => state.galleryPhoto)

  const [loaded, setLoaded] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [isLastPhotoLoaded, setIsLastPhotoLoaded] = useState(false)

  useEffect(() => {
    setIsLastPhotoLoaded(!isPhotosFetching && photos[photos.length - 1]?.id === photo.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const observerOnChange = isVisible => setIsIntersecting(isVisible)

  const onclick = () => {
    if (multiSelectEnabled && !photoAction.cantDeletePhoto(photo)) {
      photoIdsToDelete.includes(photo.id) ? photosToDeleteActions.delete([photo.id]) : photosToDeleteActions.add([photo.id])
    } else if (!multiSelectEnabled) {
      photoAction.set(photo) // @todo should be done in viewer page?
      history.push(`/camera/${photo.cameraId}/photo/${photo.id}`)
    }
  }

  const displayLoadingPlaceholder = () =>
    isNewUi
      ? <Box className={clsx(classes.photoWrapperNoImg, classes.roundedEdges)}><VoskerLoadImageIcon className={classes.noImgIcon} /></Box>
      : <IconNoPhoto notfound={notFound} width="160px" height="90px" />

  const displayEmptyPlaceholder = () =>
    isNewUi
      ? (
        <div className={clsx(classes.photoWrapper, classes.roundedEdges)}>
          <Box className={classes.photoWrapperNoImg}><VoskerNoImgIcon className={classes.noImgIcon} /></Box>
        </div>
        )
      : (
        <div className={classes.photoWrapper}><IconNoPhoto notfound={notFound} width="160px" height="90px" />
        </div>
        )

  const imageStyle = loaded
    ? classes.loadedImageStyle
    : classes.defaultImageStyle

  return (
    <div className={classes.photo}>
      <div className={notFound ? classes.photoNotFound : classes.tileWrapper}>
        <div onClick={onclick}>
          { !photoAction.cantDeletePhoto(photo)
            ? <span className={classes.selectCheckbox}><SelectPhoto photoId={photo.id} isNewUi={isNewUi} /></span>
            : null }

          { videoState.isAStream(photo) && loaded
            ? <div className={isNewUi ? clsx(classes.gradient, classes.roundedEdges) : classes.gradient} />
            : null }

          { loaded && <PhotoOverlay photo={photo} /> }
          { notFound && displayEmptyPlaceholder() }
          { !notFound && (
            <LazyLoad height={90} offset={300} once resize overflow={isNewUi}>
              <div className={isNewUi ? clsx(classes.photoWrapper, classes.roundedEdges) : classes.photoWrapper}>
                { !loaded && displayLoadingPlaceholder() }
                <img
                  id={photo.id}
                  alt={photo.id}
                  src={photo.urls.medium}
                  className={imageStyle}
                  onError={() => setNotFound(true)}
                  onLoad={() => setLoaded(true)}
                />
              </div>
            </LazyLoad>
          ) }
        </div>
      </div>

      { isNewUi && !isPhotosFetching && isLastPhotoLoaded && <Observer onChange={observerOnChange} /> }
    </div>
  )
}

export default PhotoTile
