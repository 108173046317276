import React from 'react'
import Box from '@mui/material/Box'
import { makeStyles } from '@material-ui/core/styles'
import SyncPendingIcon from 'assets/icons/SyncPendingIcon'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  alert: {
    display: 'flex',
    background: '#F9B6431A',
    marginTop: '45px',
    border: '1px solid #F9B643',
    padding: '12px 6px 12px 0',
    borderRadius: '5px',
  },
  content: {
    fontFamily: 'ibm-plex-sans-regular',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '21px',
    textAlign: 'left',
  },
  subContent: {
    fontFamily: 'ibm-plex-sans-regular',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'left',
  },
  date: {
    fontFamily: 'ibm-plex-sans-regular',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '16px',
    textAlign: 'left',
  },
  link: ({ isDe, isEn }) => ({
    marginLeft: isDe ? '565px' : isEn ? '620px' : '605px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '520px',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '5px',
    },
  }),
}))
const WarningBox = ({ Icon = SyncPendingIcon, text, subText, style, content, date, link, LinkIcon }) => {
  const { t, i18n } = useTranslation()
  const isDe = i18n.language === 'de'
  const isEn = i18n.language === 'en'
  const classes = useStyles({ isDe, isEn })
  const theme = useTheme()
  const isMobileResolution = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box className={classes.alert} style={style}>
      <Icon style={{ height: '14px', marginLeft: '8px', position: 'relative', top: '2px' }} />
      <Box display="inline-flex">
        <span className={classes.content}>
          { content && <span className={classes.subContent}>{ content }</span> }{ date && <span className={classes.date}>{ date }</span> }
          { link && <>{ isMobileResolution && <br /> } <span className={classes.link}>{ link }<LinkIcon style={{ height: '14px', marginLeft: '4px', position: 'relative', top: '2px' }} /> </span> </> }
          { text && <span>{ text } </span> } <br />
          { subText && <span className={classes.subContent}>{ subText }</span> }
        </span>
      </Box>
    </Box>
  )
}

export default WarningBox
