import { useEffect } from 'react'

import Fab from '@material-ui/core/Fab'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import usePhotoViewerNavigation from 'photo-module/photo/core/usePhotoViewerNavigation'
import useSelectedPhoto from 'photo-module/photo/core/useSelectedPhoto'
import useKeyPress from 'shared-module/components/key-press/useKeyPress'
import VoskerButtonWrapper from './VoskerButtonWrapper'
import NextArrowIcon from '../icons/viewer/NextArrowIcon'

const useStyles = makeStyles(theme => ({
  next: {
    display: 'flex',
    position: 'absolute',
    height: 'calc(100vh - 128px)',
    width: '40vw',
    alignItems: 'center',
    transition: '.2s all',
    cursor: 'ew-resize',
    opacity: 0,
    right: '20px',
    marginRight: '-20px',
    paddingRight: '20px',
    justifyContent: 'flex-end',
    '&:hover': {
      opacity: 100,
    },
    [theme.breakpoints.down('xs')]: {
      height: '20%',
    },
  },
  arrow: {
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.background.tile,
    },
  },
  icon: {
    marginLeft: 2,
  },
}))

const NextButton = ({ isNewUi = false }) => {
  const classes = useStyles()
  const arrowRightPressed = useKeyPress('ArrowRight')
  const photo = useSelectedPhoto()
  const { nextPhoto, switchPhoto } = usePhotoViewerNavigation(photo?.id)

  const next = () => switchPhoto(nextPhoto)

  useEffect(() => {
    if (arrowRightPressed && nextPhoto) { next() }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrowRightPressed])

  const renderButton = () => {
    return isNewUi
      ? (
        <Box onClick={next} className={classes.next}>
          <VoskerButtonWrapper>
            <NextArrowIcon className={classes.icon} fontSize="large" />
          </VoskerButtonWrapper>
        </Box>
        )
      : (
        <Box onClick={next} className={classes.next}>
          <Fab className={classes.arrow} color="secondary">
            <ChevronRightIcon className={classes.icon} fontSize="large" />
          </Fab>
        </Box>
        )
  }

  return nextPhoto
    ? <>{ renderButton() }</>
    : null
}

export default NextButton
