import { useState, useEffect } from 'react'
import useQueryParams from 'shared-module/url/useQueryParams'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import useDirectPurchaseLogic from './useDirectPurchaseLogic'
import useDirectPurchaseLogicEcommerce from './useDirectPurchaseLogicEcommerce'
import { useECommerceFlag } from '../../../../ecommerce-module/hooks/UseEcommerceFlag'
import eCommerceApi from 'ecommerce-module/api/eCommerceApi.js'
import { useECommerceProductsActions } from 'ecommerce-module/core/eCommerce.hook'
import commerceSelector from 'ecommerce-module/core/eCommerce.selectors'

const DirectPurchaseWrapper = ({ children, isNewUi, basket, productItems }) => {
  const useStyles = makeStyles({
    root: {
      marginTop: isNewUi ? '0px' : '150px',
      overflow: 'hidden',
      marginBottom: 10,
    },
  })
  const classes = useStyles()
  const eCommerceEnabled = useECommerceFlag()
  const queryParams = useQueryParams()
  const { getPlans } = useECommerceProductsActions()
  const { directPurchase, isCartItemsFetched } = useDirectPurchaseLogic()
  const { directPurchaseEcommerce } = useDirectPurchaseLogicEcommerce()
  const [addons, setAddons] = useState(null)
  const [insiders, setInsider] = useState(null)
  const [itemInCart, setItemInCart] = useState(null)
  const cartIsEmpty = basket?.productTotal === 0

  useEffect(() => {
    if (productItems) {
      const productIds = productItems && productItems.map(item => item.productId)
      productIds?.includes(commerceSelector.salesForceInsidersClub) ? setItemInCart(true) : setItemInCart(false)
    }
  }, [productItems, itemInCart])

  useEffect(() => {
    if (queryParams.photo_hd === true || queryParams.video_hd === true) {
      if (!addons) {
        getPlans().then((addon) => {
          setAddons(addon.plans?.plans)
        })
      }
    }
  }, [addons])
  useEffect(() => {
    const getInsidersClub = async () => {
      if (queryParams.become_member === true) {
        if (!insiders) {
          const insidersClub = await eCommerceApi.fetchInsidersClubProduct()
          setInsider(insidersClub[0])
        }
      }
    }
    getInsidersClub()
  }, [insiders])

  useEffect(() => {
    if (eCommerceEnabled) {
      if (queryParams.become_member === true) {
        if (itemInCart === false) {
          directPurchaseEcommerce(insiders)
        }
        if (cartIsEmpty) {
          directPurchaseEcommerce(insiders)
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insiders, itemInCart, cartIsEmpty])

  useEffect(() => {
    if (eCommerceEnabled && addons) {
      if (queryParams.photo_hd === true) {
        const photoHdAddon = addons && addons?.filter(addon => addon?.id === commerceSelector.salesForceHdPackageId)
        addons && directPurchaseEcommerce(photoHdAddon, basket)
      }
      if (queryParams.video_hd === true) {
        const videoHdAddon = addons && addons?.filter(addon => addon?.id === commerceSelector.salesForceHdVideoPackageId)
        directPurchaseEcommerce(videoHdAddon, basket)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addons])

  useEffect(() => {
    if (!eCommerceEnabled) {
      isCartItemsFetched && directPurchase()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCartItemsFetched])

  return (
    <Grid
      className={classes.root}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      { children }
    </Grid>
  )
}

export default DirectPurchaseWrapper
