import { useTranslation } from 'react-i18next'
import { Redirect, useLocation, Switch, Route } from 'react-router-dom'

import { Box, Container } from '@material-ui/core'
import CTabs from 'storybook-component-module/src/components/tabs/CTabs'

import cameraSharingIcon from 'assets/icons/navigation/cameraSharingIcon'
import CAppBar from 'storybook-component-module/src/components/containers/CAppBar'
import { getCameraSharingTabs } from './CameraSharingTabsHelper'
import GuestPanel from './guest-panel/GuestPanel'
import CamerasPanel from './CamerasPanel'
import Divider from '@material-ui/core/Divider'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'
import { useDispatch } from 'react-redux'

const CameraSharingPage = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const tabs = getCameraSharingTabs()

  const getAccountDashboardTabs = [
    {
      id: 'profile',
      path: '/account/profile',
      label: 'Profile',
      maxWidth: 'lg',
      disabled: false,
    },
    {
      id: 'payment',
      path: '/account/payment',
      label: 'app:account.payment.title',
      maxWidth: 'sm',
      disabled: false,
    },
    {
      id: 'management',
      path: '/account/management',
      label: 'app:account.management.title',
      maxWidth: 'lg',
      disabled: false,
    },
  ]

  const getCurrentTabPath = (tabs, pathname) => {
    // The vosker mobile app may send parameters through URL and that's way
    // the path can be longer than usual
    const path = pathname.includes('/account/management') ? '/account/management' : pathname
    return (
      tabs.filter(({ disabled }) => !disabled).map(tab => tab.path).includes(path) ? path : tabs[0].path
    )
  }

  const tabIndex = getCurrentTabPath(tabs, pathname)
  const theme = useTheme()
  const isMobileResolution = useMediaQuery(theme.breakpoints.only('xs'))
  const userMembership = useUserClubMembership()
  return (
    <Box sx={{ marginTop: { xs: '103px', sm: '128px' } }} data-testid="account">
      <CAppBar Icon={cameraSharingIcon} title={t('camera_sharing.page_title')} />
      <Box>
        <Container maxWidth={false} style={{ paddingTop: 32 }}>
          <CTabs tabs={tabs} tabIndex={tabIndex} />
        </Container>
        <Divider variant="fullWidth" />
      </Box>
      <Container maxWidth={false} style={{ padding: isMobileResolution ? 0 : undefined }}>
        <Switch>
          <Route
            path="/camera-sharing/guests"
            render={(props) => <GuestPanel {...props} isUserClub={userMembership.isMember} />}
          />
          <Route
            path="/camera-sharing/cameras"
            render={(props) => <CamerasPanel {...props} isUserClub={userMembership.isMember} />}
          />
          <Redirect to="/camera-sharing/guests" />
        </Switch>
      </Container>
    </Box>
  )
}

export default CameraSharingPage
