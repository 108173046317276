import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Amount from 'shared-module/components/amount/Amount'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextSmall from 'spypoint/src/components/TextSmall'
import FormControl from '@material-ui/core/FormControl'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  plus: {
    width: '32px',
    height: '1.875rem',
    background: `linear-gradient(360deg, rgba(0, 0, 0, 0) 24.74%, ${theme.palette.background.viewer} 196.91%)`,
    borderRadius: '3px',
    display: 'inline-block',
    textAlign: 'center',
    alignItems: 'center',
    color: theme.palette.secondary.main,
    fontWeight: '400',
    fontSize: '16px',
    marginRight: '4px',
    border: `2px solid ${theme.palette.background.paper}`,
    cursor: 'pointer',
    userSelect: 'none',
  },
  input: {
    border: `2px solid ${theme.palette.background.paper}`,
    borderRadius: '3px',
    display: 'inline-block',
    width: '42px',
    height: '1.875rem',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginRight: '4px',
    userSelect: 'none',
  },
  price: {
    marginRight: 10,
    color: theme.palette.secondary.main,
  },
  priceReposition: {
    position: 'relative',
    top: '0.75rem',
  },
  disabled: {
    pointerEvents: 'none',
    visibility: 'hidden',
  },
  select: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&:hover .MuiOutlinedInput-root': {
      borderRadius: 6,
      border: `1.5px solid ${theme.palette.secondary?.light}`,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 6,
      border: `1.5px solid ${theme.palette.background.paper}`,
      '& .Mui-focused fieldset': {
        border: 'none',
      },
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      borderRadius: 6,
    },
    '& .MuiSelect-select': {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '0.625rem 1.5rem',
    },
    '& .MuiTypography-root': {
      paddingRight: '0.25rem',
      marginLeft: '-0.325rem',
    },
  },
}))

const ItemQuantitySelector = ({ product, setQuantity, currencyCode, checkout }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const inCheckout = history.location.pathname === '/checkout'
  const quantities = () => Array.from({ length: 10 }, (value, key) => key + 1)

  const onQuantityChangeInCart = subtract => {
    let quantity = product.quantity
    subtract ? --quantity : ++quantity
    setQuantity(quantity)
  }

  const onQuantityChangeInCheckout = event => setQuantity(event.target.value)

  return (
    <>
      <Grid item xs={3}>
        <Typography variant="body2">
          { t('app:checkout_process.hd_photos.quantity') }
        </Typography>
      </Grid>

      <Grid item>
        <span className={inCheckout ? clsx(classes.price, classes.priceReposition) : classes.price}>
          <Amount
            value={product.basePrice}
            currencyCode={currencyCode}
          />
          { ' ' }
          { t('app:checkout_process.hd_photos.each') }
        </span>

        { history.location.pathname === '/checkout'
          ? (
            <FormControl variant="outlined" margin="none" className={classes.select}>
              <Select
                value={product.quantity}
                onChange={onQuantityChangeInCheckout}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                { quantities().map((quantity) => <MenuItem key={quantity} value={quantity}><TextSmall>{ quantity }</TextSmall></MenuItem>) }
              </Select>
            </FormControl>
            )
          : (
            <>
              <Typography
                className={checkout && product.quantity === 1 ? clsx(classes.plus, classes.disabled) : classes.plus}
                onClick={() => onQuantityChangeInCart(true)}
              >
                -
              </Typography>

              <Typography className={classes.input}>{ product.quantity } </Typography>
              <Typography
                className={classes.plus}
                style={{ marginRight: 0 }}
                onClick={() => onQuantityChangeInCart(false)}
              >
                +
              </Typography>
            </>
            ) }
      </Grid>
    </>
  )
}

export default ItemQuantitySelector
