import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import HdPackageIcon from 'assets/icons/navigation/HdPackageIcon'
import { useLoadCameras } from 'camera-module/cameras/core/useCameras'
import useCamerasActions from 'camera-module/cameras/core/useCamerasActions'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import MenuItem from 'shared-module/navigation/menu/MenuItem'
import { useCart } from 'spypoint/src/checkout-module/order/core/cart.hooks'
import { PHOTOHD } from 'spypoint/src/checkout-module/order/core/productTypes'
import { useLDFlags } from 'spypoint/src/launchDarkly-module/launchDarklyFlagsContext'
import eCommerceActions from 'ecommerce-module/core/eCommerce.actions'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import commerceSelector from 'ecommerce-module/core/eCommerce.selectors'

const HdPackageMenuItem = ({ onClick, addons }) => {
  const { t } = useTranslation()
  const { addToCart, navigateToCart, updateQuantity, hdPhotosItemInCart } = useCart()
  const cameras = useLoadCameras()
  const { flags } = useLDFlags()
  const camerasActions = useCamerasActions()
  const nonCellCameras = camerasActions.getNonCellular(cameras)
  const hdPackageDialogActions = useConfirmationDialogActions()
  const noCameraWithHdSupport = cameras?.length === nonCellCameras?.length
  const notAllCamerasSupportHD = nonCellCameras?.length > 0
  const eCommerceEnabled = flags && flags['e-commerce']
  const history = useHistory()
  const dispatch = useDispatch()
  const { basketId, productItems } = useSelector(commerceSelector.getProductItemsSelector)

  useEffect(() => {
    if (eCommerceEnabled) {
      dispatch(eCommerceActions.fetchProductItems())
    }
  }, [eCommerceEnabled, dispatch])

  const isAddonInCart = productItems?.some((item) => {
    return item?.productId === commerceSelector.salesForceHdPackageId
  })
  const currentAddon = productItems?.filter(item => item?.productId === commerceSelector.salesForceHdPackageId)

  const cartDialogText = () => {
    if (noCameraWithHdSupport) {
      return t('app:checkout_process.hd_photos.no_device_supports')
    } else if (notAllCamerasSupportHD) {
      return t('app:checkout_process.hd_photos.not_all_device_supports')
    }
  }

  const newCartItem = product => [{
    itemId: product?.itemId,
    productId: product?.id,
    productName: product?.name,
    quantity: product?.stepQuantity,
    price: product?.price,
  }]
  const updateCartItem = product => {
    return [{
      itemId: product?.itemId,
      productId: product?.productId,
      productName: product?.itemText,
      quantity: product?.quantity + 1,
      price: product?.price,
    }]
  }

  const addExtraItem = (product) => {
    const storedBasketId = localStorage.getItem('basketId')
    if (!storedBasketId || storedBasketId === 'undefined') {
      eCommerceActions.createBaskets().then(newBasket => eCommerceActions.addItemsToBasket(basketId, newCartItem(product[0])))
    }
    if (isAddonInCart) {
      dispatch(eCommerceActions.updateBasketItems(storedBasketId, updateCartItem(currentAddon[0])))
    } else {
      dispatch(eCommerceActions.addItemsToBasket(storedBasketId, newCartItem(product[0])))
    }
    navigateToCart(history)
  }

  const showCart = () => {
    const product = addons?.plans.plans.filter(item => item.id === commerceSelector.salesForceHdPackageId)

    if (eCommerceEnabled) {
      addExtraItem(product)
    } else {
      if (hdPhotosItemInCart) {
        updateQuantity({
          itemId: hdPhotosItemInCart.id,
          quantity: hdPhotosItemInCart.quantity + 1,
        })
        onClick()
      } else {
        addToCart({ productId: PHOTOHD })
        if (noCameraWithHdSupport || notAllCamerasSupportHD) {
          hdPackageDialogActions.open()
        } else {
          onClick()
        }
      }
    }
    onClick()
    navigateToCart()
  }

  const hideCartHDWarning = () => {
    onClick()
    hdPackageDialogActions.close()
  }

  return (
    <>
      <MenuItem Icon={HdPackageIcon} text="menu.hd_package" onClick={showCart} path="/cart" />
      <ConfirmationDialog
        open={hdPackageDialogActions.isOpen}
        title={t('app:checkout_process.warning_title')}
        text={cartDialogText()}
        confirmText={t('button.continue')}
        onConfirm={onClick}
        onClose={hideCartHDWarning}
      />
    </>
  )
}

export default HdPackageMenuItem
