import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import MenuItem from '@material-ui/core/MenuItem'
import Link from '@material-ui/core/Link'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles(theme => ({
  icon: {
    display: 'block',
    fill: theme.palette.secondary.dark,
  },
  link: {
    '&:hover': {
      textDecoration: 'none!important',
    },
  },
  customLink: {
    '&:hover': {
      background: theme.palette.background.tile,
      textDecoration: 'none!important',
      cursor: 'pointer',
    },
  },
}))

const InformationBarElement = ({
  text,
  Icon,
  className,
  color = 'textSecondary',
  style,
  wrapperStyle = { height: '100%', padding: '8px' },
  onClick = () => {},
  customHover = false,
  hasTooltip = false,
  dataTestId,
}) => {
  const classes = useStyles()
  const renderInformationBarElementContent = () => (
    <Grid container alignItems="center" style={wrapperStyle}>
      { Icon && (
        <Grid item>
          <Icon className={classes.icon} />
        </Grid>
      ) }
      { hasTooltip
        ? (
          <Tooltip arrow title={text}>
            <Grid item style={{ marginLeft: '8px' }}>
              <Typography color={color} noWrap className={className} data-testid={dataTestId}>{ text }</Typography>
            </Grid>
          </Tooltip>
          )
        : (
          <Grid item style={{ marginLeft: '8px' }}>
            <Typography color={color} noWrap className={className} data-testid={dataTestId}>{ text }</Typography>
          </Grid>
          ) }
    </Grid>
  )

  return (
    <Link onClick={onClick} style={style} className={customHover ? classes.customLink : classes.link}>
      <Hidden lgUp>
        <MenuItem style={style} disableGutters>
          { renderInformationBarElementContent() }
        </MenuItem>
      </Hidden>
      <Hidden mdDown>
        { renderInformationBarElementContent() }
      </Hidden>
    </Link>
  )
}

export default InformationBarElement
