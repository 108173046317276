import cameraPlansApi from 'camera-module/camera/core/transmission-plan/api/cameraPlans.api'

export const SET_CAMERA_PLANS = 'SET_CAMERA_PLANS'
export const CLEAR_CAMERA_PLANS = 'CLEAR_CAMERA_PLANS'

const setCameraPlans = plans => ({ type: SET_CAMERA_PLANS, plans })
const clearCameraPlans = () => ({ type: CLEAR_CAMERA_PLANS, plans: null })

const getPlans = (cameraId) => dispatch =>
  cameraPlansApi.getCameraPlans(cameraId)
    .then(plans => dispatch(setCameraPlans(plans)))

const cameraPlansActions = { getPlans, clearCameraPlans }
export default cameraPlansActions
