import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import CircleAlert from '../../../../assets/icons/CircleAlert'
import SecondaryButton from 'shared-module/components/button/SecondaryButton'
import cameraCommandsApi from '../../api/cameraCommandsApi'
import messageActions from 'shared-module/message/messageActions'
import useSelectedCamera from '../../core/useSelectedCamera'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { UPDATE_FIRMWARE } from '../../core/cameraSettingsButtonInputTypes'
import DialogCameraCommandsRequest from '../camera-settings-page/commands/DialogCameraCommandsRequest'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '20px',
    width: '200px',
    textAlign: 'center',
  },
  circleIcon: {
    fontSize: '1.1rem',
    marginRight: '5px',
  },
  value: {
    display: 'flex',
    margin: 'auto',
    fontSize: '11pt',
    fontWeight: 'bold',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.error.main,
  },
  label: {
    fontSize: '10pt',
    color: theme.palette.text.primary,
  },
  commandButton: {
    whiteSpace: 'nowrap',
    minWidth: 'max-content',
    color: 'white',
    border: '1px solid white',
    borderRadius: '6px',
    fontSize: 14,
    marginTop: 8,
    padding: '1px 10px',
    '&:hover': {
      border: '1px solid white',
    },
  },
}))

const CameraStatusUpdateTile = ({ label, icon, value, update }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const camera = useSelectedCamera()
  const [requested, setRequested] = useState(update.isSet)
  const dispatch = useDispatch()
  const dialogActions = useConfirmationDialogActions()
  const updateFirmware = (unset = false) => {
    cameraCommandsApi
      .requestCommand(camera.id, UPDATE_FIRMWARE, unset)
      .then((command) => setRequested(command.isSet))
      .catch(() => dispatch(messageActions.showError('errors.catch_all')))
  }

  return (
    <div className={classes.root} data-testid="tile">
      <div>{ icon }</div>
      <div className={classes.value} data-testid="FirmwareVersion">
        <CircleAlert className={classes.circleIcon} /> { value }
      </div>
      <div className={classes.label}>{ t(label) }</div>
      { requested
        ? (
          <SecondaryButton
            align="right"
            color="primary"
            variant="outlined"
            className={classes.commandButton}
            disabled={requested}
            onClick={() => updateFirmware(true)}
            value={t('camera.settings.buttons.processing')}
          />
          )
        : (
          <>
            <SecondaryButton
              align="right"
              color="primary"
              variant="outlined"
              className={classes.commandButton}
              onClick={() => dialogActions.open()}
              value={t('camera.settings.buttons.firmware')}
            />
            <DialogCameraCommandsRequest
              camera={camera}
              commandName={UPDATE_FIRMWARE}
              open={dialogActions.isOpen}
              onClose={dialogActions.close}
              onConfirm={() => {
                dialogActions.close()
                updateFirmware()
              }}
            />
          </>
          ) }
    </div>
  )
}

export default CameraStatusUpdateTile
